import { Autocomplete, IconButton, Stack, Chip, Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, Grid, TextField, Card, CardContent, List, ListItem, Typography, CardActions } from "@mui/material";
import axios from "axios"
import React, { useEffect, useState } from "react";
import { getApiDomain } from "../../../App";
import { useHotSchedules } from "../../../context/HotSchedules";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useGiftCard } from "../../../context/GiftCards";
import { useNavigate } from "react-router";

const url = getApiDomain();


export default function Teams() {
    const [open, setOpen] = useState(false);
    const {members, teams, loadTeams, addTeam, addEmployees, deleteMember, deleteTeam} = useGiftCard();
    const [teamToModify, setTeamToModify] = useState("");
    const [openEmployee, setOpenEmployee] = useState(false);

    const nav = useNavigate();
    const handleDialog = () => {
        setOpen(!open);
    }
    const handleEmployee = () => {
        setOpenEmployee(!openEmployee);
    }
    const {employees} = useHotSchedules();

    useEffect(()=>{
        loadTeams();
    }, [])
    return(
        <Box>
            <NewTeamDialog open={open} handleClose={handleDialog} addTeam={addTeam} />
            <EmployeeSelectDialog open={openEmployee} handleClose={handleEmployee} onAdd={(employees)=> {
                addEmployees(teamToModify, employees);
            }}/>
            <Typography variant="h3">Gift Card Teams             
            </Typography>

            <Button
                onClick={()=>{
                    handleDialog();
                }}
            >
                Add Team
            </Button>            <Button
                onClick={()=>{
                    nav("/dashboard/giftcards/teams");
                }}
            >
                Stop Modifying
            </Button>
            <Grid container spacing={2}>
                {
                    teams.map((team)=>{
                        console.log(team.id)
                        console.log(members[team.id])
                        return(
                            <Grid key={team.id} item lg={6} xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">Team: {team.TeamName}</Typography>
                                        <List>
                                            {
                                                members[team.id] ? members[team.id].map((member)=>{
                                                    return(
                                                        <ListItem>
                                                            <Chip label={employees[member] ? employees[member].name:""}  onDelete={()=>deleteMember(member)}/>
                                                        </ListItem>
                                                    )
                                                }) : ""
                                            }

                                        </List>
                                    </CardContent>
                                    <CardActions>
                                    <Button
                                            onClick={()=>{
                                                setTeamToModify(team.id);
                                                handleEmployee();
                                            }}
                                        >
                                            Add Team Member
                                        </Button>
                                        <Button
                                            onClick={()=>{
                                                deleteTeam(team.id);
                                            }}
                                        >
                                            Delete Team
                                        </Button>
                                    </CardActions>
                                </Card>

                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
const EmployeeSelectDialog = (props) =>{
    const {open, onAdd, handleClose} = {...props}
    const {employees} = useHotSchedules();
    const [employeeList, setEmployeeList] = useState([]);
    const addEmployee = (employeeId) => {
        const tempEmployeeList = Array.from(employeeList);
        if(tempEmployeeList.indexOf(employeeId) === -1){
            tempEmployeeList.push(employeeId)
            setEmployeeList(Array.from(tempEmployeeList))
        }

    }
    const handleDelete = (employeeId) => {
        const tempEmployeeList = Array.from(employeeList);
        const index = tempEmployeeList.indexOf(employeeId);
        if(index != -1){
            tempEmployeeList.splice(index, 1);
        }
        setEmployeeList(Array.from(tempEmployeeList));
    }
    const [employeeValue, setEmployeeValue] = useState("");
    const [inputValue, setInputValue] = React.useState('');
    return(
        <React.Fragment>
            <Dialog 
                style={{ zIndex: 1800 }}
                onClose={handleClose} open={open} sx={{minHeight:500}}>
                <DialogTitle>
                    Add Employee
                </DialogTitle>
                <Box style={{ width: 400, height: 200 }} padding={2}>
                    <Autocomplete
                        value={employeeValue}
                        onChange={(event, newValue) => {
                            setEmployeeValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        disablePortal
                        id="combo-box-demo"
                        options={Object.keys(employees).map((employee)=>{
                            return {
                                label: employees[employee].name,
                                id: employee
                            }
                        })}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Employee Name" />}
                        />
                                        <IconButton color="primary" aria-label="upload picture" component="label" onClick={()=>{
                    addEmployee(employeeValue.id, employeeList);
                }}>
                    <AddCircleOutlineIcon />
                </IconButton>
                <p />
                Team Members to Add:       

                <Stack 
                      sx={{ flexWrap: 'wrap', gap: 1 }}

                    direction="row" spacing={1}>
                    {
                        employeeList.map((employee)=>{
                            return(
                                <Chip label={employees[employee].name}  onDelete={()=>handleDelete(employee)}/>
                            )
                        })
                    }

                </Stack>
                <Button onClick={()=>{
                        handleClose()
                    }}>Cancel</Button>
                    <Button onClick={()=>{
                        if(employeeValue !== null){
                            onAdd(employeeList);
                            handleClose();
                        }
                    }}>Add Team members</Button>
                </Box>
                <DialogActions>


                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}
const NewTeamDialog = (props) =>{
    const {handleClose, open, addTeam} = {...props}
    const [employeeList, setEmployeeList] = useState([]);
    const {employees} = useHotSchedules();
    const [employeeValue, setEmployeeValue] = useState("");
    const [inputValue, setInputValue] = React.useState('');

    const [name, setName] = useState("");
    const handleChange = (event) => {
        setName(event.target.value);
    }
    const handleDelete = (employeeId) => {
        const tempEmployeeList = Array.from(employeeList);
        const index = tempEmployeeList.indexOf(employeeId);
        if(index != -1){
            tempEmployeeList.splice(index, 1);
        }
        setEmployeeList(Array.from(tempEmployeeList));
    }
    const addEmployee = (employeeId) => {
        const tempEmployeeList = Array.from(employeeList);
        if(tempEmployeeList.indexOf(employeeId) === -1){
            tempEmployeeList.push(employeeId)
            setEmployeeList(Array.from(tempEmployeeList))
        }

    }

    return(
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                Add a new team
            </DialogTitle>
            <DialogContent>
                <TextField id="team-name" label="Team Name" variant="standard" value={name} onChange={handleChange}/>
                <p />
                <Autocomplete
                        value={employeeValue}
                        onChange={(event, newValue) => {
                            setEmployeeValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        disablePortal
                        id="employee-list"
                        options={Object.keys(employees).sort((a, b)=>{
                            if(employees[a].name && employees[b].name){
                                return employees[a].name.localeCompare(employees[b].name)
                            } else {
                                return 0;
                            }
                        }).map((employee)=>{
                            return {
                                label: employees[employee].name,
                                id: employee
                            }
                        })}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Employee Name" />}
                        />
                <IconButton color="primary" aria-label="upload picture" component="label" onClick={()=>{
                    addEmployee(employeeValue.id, employeeList);
                }}>
                    <AddCircleOutlineIcon />
                </IconButton>
                <p />
                Team Members:       

                <Stack direction="row" spacing={1}>
                    {
                        employeeList.map((employee)=>{
                            return(
                                <Chip label={employees[employee].name}  onDelete={()=>handleDelete(employee)}/>
                            )
                        })
                    }

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={()=>{
                        setName("");
                        addTeam(name, employeeList);
                        handleClose();
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}