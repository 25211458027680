import { Button, Grid, MenuItem, Select, Box, Typography, LinearProgress,LinearProgressProps } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useHotSchedules } from "../../context/HotSchedules"
import { getApiDomain, getOvertimeTrackerApi } from "../../App";
import moment from "moment-timezone";
import axios from "axios";

const skillLevel = {   "1059550680": {
    "skillId": 1059550680,
    "rank": 0,
    "name": "01"
  },
  "1059550682":{
    "skillId": 1059550682,
    "rank": 1,
    "name": "02"
  },
  "1059550684":{
    "skillId": 1059550684,
    "rank": 2,
    "name": "03"
  }
};
const skillThreshold = {
    "03": {
        blast: 1,
        "skillId": 1059550684,

    },
    "02": {
        blast: .90,
        "skillId": 1059550682,
    },
    "01": {
        blast: 0,
        "skillId": 1059550680,

    }

}
var option = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  
  var formatter = new Intl.NumberFormat("en-US", option);

export default function ServerList(props){
    const {employeeJobcodes, loading, employees, setEmployeeJobcodes, jobcodes} = useHotSchedules();
    const handleSkilllevelListChange = (employeeKey, newValue) => {
        const tempEmployeeJobcodes = {...employeeJobcodes};
        tempEmployeeJobcodes[employeeKey][641360538].skillLevelId = newValue;
        tempEmployeeJobcodes[employeeKey][641360538].skillLevel = skillLevel[newValue].name;
        setEmployeeJobcodes({...tempEmployeeJobcodes})
        if(!serverBlast[employeeKey]){
            let tempChangeJobcodes = [];
            tempChangeJobcodes.push(tempEmployeeJobcodes[employeeKey]);
            processQueue(tempChangeJobcodes);

            // If they don't have a blast score, process their skill level change
        }
    }
    let employeeCount = 0;
    let [weekKeys, setWeekKeys] = useState([]);
    let [serverBlast, setServerBlast] = useState({});
    let [changeJobcodes, setChangeJobcodes] = useState([]);
    const [progress, setProgress] = React.useState(0);
    let [completed, setCompleted] = useState(0);
    let [currentEmployeeUpdating, setCurrentEmployeeUpdating] = useState(null)
    let [toUpdate, setToUpdate] = useState(0);

    useEffect(()=>{
        loadingBlastScore();
    }, []);
    const updateSkillLevel = async () =>{
        let tempEmployeeJobcodes = {...employeeJobcodes};
        let tempChangeJobcodes = [];
        setProgress(0);
        Object.keys(employeeJobcodes)
        .filter((employeeKey)=>{
            if(employees[employeeKey] && tempEmployeeJobcodes[employeeKey][641360538]){
                return true;
            }
        })
        .forEach((employeeKey)=>{
            // If there is a blast score
            if(serverBlast[employeeKey]){
                if(serverBlast[employeeKey]["skillLevelId"] !== tempEmployeeJobcodes[employeeKey][641360538].skillLevelId){
                    tempEmployeeJobcodes[employeeKey][641360538].skillLevelId = serverBlast[employeeKey]["skillLevelId"];
                    tempEmployeeJobcodes[employeeKey][641360538].skillLevel = skillLevel[serverBlast[employeeKey]["skillLevelId"]].name;
                    tempChangeJobcodes.push(tempEmployeeJobcodes[employeeKey])
                }

            }
        })
        setToUpdate(tempChangeJobcodes.length);
        setCompleted(0);
        setEmployeeJobcodes({...tempEmployeeJobcodes});
        processQueue(tempChangeJobcodes, 0, tempChangeJobcodes.length);

    }
    const loadingBlastScore = () => {
        let date = moment();
        console.log(date.day())
        if(date.day() === 3){
            date.subtract(2, "week")
        } else {
            date.subtract(1, "week")
        }
        console.log(date.format("YYYY-MM-DD"))
        axios.get(getApiDomain() + "/financial/blast/"+date.format("YYYY-MM-DD")).then((res)=>{
            const tempServerBlast = {};
            res.data.blast.forEach((week)=>{
                week.forEach((server)=>{
                    if(!tempServerBlast[server.employeeId]){
                        tempServerBlast[server.employeeId] = {};
                    }         
                    let key = "P" + server.period + "W" + server.week;
                    tempServerBlast[server.employeeId][key] = server;
                })
            })
            Object.keys(tempServerBlast).forEach((server)=>{
                let serverData = tempServerBlast[server];
                let keys = Object.keys(serverData);
                let tempTotal = 0;
                keys.forEach((key)=>{
                    tempTotal += serverData[key].total
                })
                let average = (keys.length > 0) ? (tempTotal / keys.length) : 0;
                tempServerBlast[server]["average"] = average;
                if(average >= skillThreshold["03"].blast){
                    tempServerBlast[server]["skillLevelId"] = skillThreshold["03"].skillId;
                } else if(average > skillThreshold["02"].blast){
                    tempServerBlast[server]["skillLevelId"] = skillThreshold["02"].skillId;

                } else{
                    tempServerBlast[server]["skillLevelId"] = skillThreshold["01"].skillId;

                }
                

            })
            setServerBlast({...tempServerBlast});
            console.log(res.data.keys)
            setWeekKeys(Array.from(res.data.keys));
        })

    }
    const processQueue = async (tempChangeJobcodes, current, original) => {
        if(tempChangeJobcodes.length > 0){
            let employee = tempChangeJobcodes.shift();
            let jobs = formatJobs(employee)
            console.log(jobs.jobs.length)
            setCurrentEmployeeUpdating(jobs.id)
            if(jobs.jobs.length < 1){
                console.log("Error")
                console.log(employee);
                processQueue(tempChangeJobcodes, current + 1, original);
            } else {
                console.log("Updating "+ employees[jobs.id].name)
                axios.post(getOvertimeTrackerApi() + "/json/jobcodeupdate", {employeeJobcodes: jobs.jobs, databaseUpdate: employee, id: jobs.id}).then((res)=>{
                    console.log("Success")
                    setChangeJobcodes(Array.from(tempChangeJobcodes));
                    setCompleted(current+1);
                    setProgress(((current+1)/original)*100)
                    setCurrentEmployeeUpdating(null)
                    processQueue(tempChangeJobcodes, current + 1, original);
                })
                .catch((error)=>{
                    console.log(error);
                    setChangeJobcodes(Array.from(tempChangeJobcodes));
                    setProgress((current/original)*100)
                    processQueue(tempChangeJobcodes, current + 1, original);
                })
            }
        }
    }
    const formatJobs = (employee)=>{
        let formattedJobcodes = [];
        let id;
        Object.keys(employee).forEach((key)=>{
            let jobcode = employee[key];
            id = jobcode.employeeId;
            let tempFormattedJobcode = { 
                "jobId": jobcode.jobId, 
                "jobName": jobcodes[jobcode.jobId].jobName, 
                "assigned": true, 
                "primary": false, 
                "goingToBePrimary": false, 
                "disabled": false, 
                "hourlyRate": jobcode.payrate, 
                "hourlyRateSource": null, 
                "rateEffectiveDate": null, 
                "externalRef": jobcodes[jobcode.jobId].externalRef, 
                "skillRatingId": jobcode.skillLevelId, 
                "groupLevel": true, 
                "upcomingRateChanges": [], 
                "flags": [], 
                "certificationRequired": false, 
                "readOnly": true, 
                "employeeId": jobcode.employeeId 
            }
            formattedJobcodes.push(tempFormattedJobcode);

        })
        return {
            jobs: formattedJobcodes,
            id: id}
    }

    return(
        <React.Fragment>
            Server BLAST List <Button onClick={()=>{updateSkillLevel()}}>Update Skill Level</Button>
            <div>
                {completed} / {toUpdate}{currentEmployeeUpdating !== null ? " - Updating " + employees[currentEmployeeUpdating].name : ""}
                <LinearProgressWithLabel value={progress} />
            </div>
            <Grid container>
                <Grid item xs={2}>
                    Name
                </Grid>
                {
                    weekKeys.map((week)=>{
                        return(
                            <Grid item xs={1}>
                                {week}
                            </Grid>
                        )
                    })
                }

                <Grid item xs={1}>
                    Average
                </Grid>
                <Grid item xs={1}>
                    New Skill Level
                </Grid>
                <Grid item xs={4}>
                    Skill
                </Grid>
                {
                    Object.keys(employeeJobcodes)
                    .filter((employeeKey)=>{
                        if(employees[employeeKey]){
                            return true;
                        }
                    }).sort((a, b)=>{
                        if(employees[a] && employees[b]){
                            if(employees[a].name >= employees[b].name){
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return 0
                        }

                    }).map((employeeKey)=>{
                        if(employees[employeeKey]){
                            if(employeeJobcodes[employeeKey][641360538]){
                                employeeCount+=1;
                                return(
                                    <React.Fragment>
                                        <Grid item xs={12} sx={{backgroundColor: employeeCount % 2 === 0 ? "#F8F8F8": "white", margin: "auto"}}>
                                            <Grid container>
                                                <Grid item xs={2}  sx={{margin: "auto"}}>
                                                    {employees[employeeKey].name }
                                                </Grid>
                                                {
                                                    weekKeys.map((week)=>{
                                                        return(
                                                            <Grid item xs={1} sx={{margin: "auto"}}>
                                                                {serverBlast[employeeKey]? serverBlast[employeeKey][week] ? formatter.format(serverBlast[employeeKey][week].total) : "" : ""}
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                <Grid item xs={1} sx={{margin: "auto"}}>
                                                    {serverBlast[employeeKey]? serverBlast[employeeKey]["average"] ? formatter.format(serverBlast[employeeKey]["average"]) : "" : ""}

                                                </Grid>
                                                <Grid item xs={1} sx={{margin: "auto"}}>
                                                    {serverBlast[employeeKey] ? skillLevel[serverBlast[employeeKey].skillLevelId].name : ""}
                                                </Grid>
                                                <Grid item xs={4} sx={{margin: "auto"}}>
                                                    <Select
                                                        labelId={"level-select-label"+employeeKey}
                                                        id={"level-select-"+employeeKey}
                                                        value={employeeJobcodes[employeeKey][641360538].skillLevelId}
                                                        onChange={(event)=>{
                                                            handleSkilllevelListChange(employeeKey, event.target.value);
                                                        }}
                                                    >
                                                        {
                                                            Object.keys(skillLevel).map((key)=>{
                                                                let skill = skillLevel[key];
                                                                return(
                                                                    <MenuItem value={skill.skillId}>{skill.name}</MenuItem>

                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </React.Fragment>
                                )
                            }

                        }


                    })
                }
            </Grid>
        </React.Fragment>
    )
}

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }