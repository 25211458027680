import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import { getApiDomain } from "../App";
const url = getApiDomain();

export const InventoryContext = React.createContext({});
export const InventoryProvider = ({children}) => {
    const [inventory, setInventory] = useState({});
    const [previousInventory, setPreviousInventory] = useState({})
    const [inventoryDate, setInventoryDate] = useState(null);
    const [openItem, setOpenItem] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [inventoryArray, setInventoryArray] = useState({});    

    const openItemDialog = (activeItem) => {
        console.log("Opening for " + activeItem)
        setActiveItem(activeItem);
        setOpenItem(true);
    }

    const closeItemDialog = () =>{
        setOpenItem(false);
        setActiveItem(null);
    }

    useEffect(()=>{
        let tempInventory = {};
        Object.keys(inventory).forEach((category)=>{
          let items = inventory[category];
          items.forEach((item)=>{
              item["category"] = category;
              tempInventory[item.itemId] = item;
          })
      });
      setInventoryArray({...tempInventory})
      }, [inventory])
      
      /*
        This use effect is used to load previous 3 weeks of data. 
      */
        useEffect(()=>{   
            if(inventoryDate){
              axios.get(url + "/inventory/previous?weeks=4&date="+inventoryDate.unix()).then((res)=>{
                let prevInventories = {};
                if(res.data.data.inventory){
                  res.data.data.inventory.forEach((item)=>{
                    if(!prevInventories[item.itemId]){
                      prevInventories[item.itemId] = []
                    }
                    prevInventories[item.itemId].push(item);
                  })
                }
                setPreviousInventory({...prevInventories})
              })
            }
    
          }, [inventoryDate])

    return(<InventoryContext.Provider value={{openItem, activeItem, closeItemDialog, openItemDialog, inventory, setInventory, previousInventory, setPreviousInventory, inventoryDate, setInventoryDate, inventoryArray, setInventoryArray}}>{children}</InventoryContext.Provider>)
}

export const useInventory= ()=>{
    return useContext(InventoryContext)
}