import { Box, Grid, CardContent, Card, Typography, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useGiftCard } from "../../../context/GiftCards";
import { useHotSchedules } from "../../../context/HotSchedules";
import { saveAs } from 'file-saver';
import Excel from "exceljs"
import moment from "moment-timezone"
import { formatMoney } from "../../../functions";


export default function TeamOverview (props)  {
    const {teams, members, giftCardTotals, teamTotals, activeEvent, weeklyTotals} = useGiftCard();
    const {employees} = useHotSchedules();
    const nav = useNavigate();
    const exportGiftCardReport = async () => {
        console.log(employees)
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Gift Card Teams Totals");
        let teamBorderThickness = "medium"
        let row = 1;
        worksheet.getCell('A' + row).value = "Gift Card Teams Totals";
        worksheet.getCell('A' + row).style ={
            font: {
                bold: true,
                size: 18
            }
        }
        worksheet.mergeCells('A' + row + ':M'+row)
        worksheet.getCell('A' + row).alignment = {horizontal: 'center'}
        row++;
        worksheet.getCell('A' + row).value = "Updated - " + moment().format("MM/DD/YYYY");
        worksheet.getCell('A' + row).style ={
            font: {
                bold: true,
                size: 12
            }
        }
        worksheet.mergeCells('A' + row + ':M'+row)
        worksheet.getCell('A' + row).alignment = {horizontal: 'center'}
        /**
         * Adjusting column widths
         */
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 12;
        worksheet.getColumn(3).width = 12;
        worksheet.getColumn(4).width = 12;
        worksheet.getColumn(5).width = 12;
        worksheet.getColumn(6).width = 12;
        worksheet.getColumn(8).width = 20;
        worksheet.getColumn(9).width = 12;
        worksheet.getColumn(10).width = 12;
        worksheet.getColumn(11).width = 12;
        worksheet.getColumn(12).width = 12;
        worksheet.getColumn(13).width = 12;
        worksheet.getColumn(14).width = 12;

        row+=1;
        let modifier = 1;
        let previousModifierLength = 0;
        teams.forEach((team, index)=>{
            
            let firstColumn = (index % 2 === 0 ? 'A' : 'H')
            let secondColumn = (index % 2 === 0 ? 'B' : 'I')
            let thirdColumn = (index % 2 === 0 ? 'C' : 'J')
            let fourthColumn = ((index % 2 === 0 ? 'D' : 'K'))
            let fifthColumn = ((index % 2 === 0 ? 'E' : 'L'))
            let sixthColumn = ((index % 2 === 0 ? 'F' : 'M'))
            
            worksheet.getCell(firstColumn + row).value = team.TeamName;
            worksheet.getCell(firstColumn + row).style ={
                font: {
                    bold: true,
                    size: 16
                }
            }
            worksheet.mergeCells(firstColumn + (row) + ':'+ sixthColumn + (row));
            worksheet.getCell(firstColumn + row).alignment = {horizontal: 'center'}

            worksheet.getCell(firstColumn + (row + modifier)).value = "Team Total";
            worksheet.getCell(firstColumn + (row + modifier)).border = {
                left: {style:teamBorderThickness},
              };
            worksheet.getCell(sixthColumn + (row + modifier)).border = {
            right: {style:teamBorderThickness},
            };
            worksheet.getCell(firstColumn + (row + modifier)).alignment = {horizontal: 'center'};
            worksheet.getCell(secondColumn + (row + modifier)).value = teamTotals[team.id].gift_cards_total + teamTotals[team.id].roundUps[activeEvent];
            worksheet.getCell(secondColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
            worksheet.getCell(firstColumn + (row + modifier)).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'ffffff'},
            };
            worksheet.getCell(secondColumn + (row + modifier)).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'ffffff'},
            };
            worksheet.getCell(thirdColumn + (row + modifier)).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'ffffff'},
            };
            worksheet.getCell(fourthColumn + (row + modifier)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ffffff'},
                };
            worksheet.getCell(fifthColumn + (row + modifier)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ffffff'},
                };
                worksheet.getCell(sixthColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'ffffff'},
                    };
            modifier++;
            worksheet.getCell(firstColumn + (row + modifier)).value = "Name";
            worksheet.getCell(firstColumn + (row + modifier)).style ={
                font: {
                    bold: true
                }
            }
            
            worksheet.getCell(secondColumn + (row  + modifier)).value = "Total";
            worksheet.getCell(secondColumn + (row + modifier)).style ={
                font: {
                    bold: true
                }
            }
            worksheet.getCell(thirdColumn + (row  + modifier)).value = "Gift Cards";
            worksheet.getCell(thirdColumn + (row + modifier)).style ={
                font: {
                    bold: true
                }
            }
            worksheet.getCell(fourthColumn + (row + modifier)).value = "Round Ups";
            worksheet.getCell(fourthColumn + (row + modifier)).style ={
                font: {
                    bold: true
                }
            }
            worksheet.getCell(fifthColumn + (row + modifier)).value = "T.W. Gift Cards";
            worksheet.getCell(fifthColumn + (row + modifier)).style ={
                font: {
                    bold: true,
                    size: 9,
                }
            }
            worksheet.getCell(sixthColumn + (row + modifier)).value = "T.W. Round Ups";
            worksheet.getCell(sixthColumn + (row + modifier)).style ={
                font: {
                    bold: true,
                    size: 9,
                }
            }
            worksheet.getCell(firstColumn + (row + modifier)).border = {
                left: {style:teamBorderThickness},
                bottom: {style: 'thin'},
                top: {style: 'thin'}
 
              };
              worksheet.getCell(firstColumn + (row + modifier)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ffffff'},
              };
              worksheet.getCell(secondColumn + (row + modifier)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ffffff'},
              };
              worksheet.getCell(secondColumn + (row + modifier)).border = {
                bottom: {style: 'thin'},
                top: {style: 'thin'}
              }
              worksheet.getCell(thirdColumn + (row + modifier)).border = {
                bottom: {style: 'thin'},
                top: {style: 'thin'}
              }
              worksheet.getCell(fourthColumn + (row + modifier)).border = {
                bottom: {style: 'thin'},
                top: {style: 'thin'}
              }
              worksheet.getCell(fifthColumn + (row + modifier)).border = {
                bottom: {style: 'thin'},
                top: {style: 'thin'}
              }
              worksheet.getCell(sixthColumn + (row + modifier)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'ffffff'},
              };
            worksheet.getCell(sixthColumn + (row + modifier)).border = {
                right: {style:teamBorderThickness},
                bottom: {style: 'thin'},
                top: {style: 'thin'}
            };
            modifier++;
            members[team.id].forEach((member, memberIndex)=>{
                worksheet.getCell(firstColumn + (row + modifier)).value = employees[member] ? employees[member].name : "";
                worksheet.getCell(secondColumn + (row + modifier)).value = giftCardTotals[member] ? giftCardTotals[member].roundUps ? (giftCardTotals[member].gift_cards_total + giftCardTotals[member].roundUps[activeEvent]): giftCardTotals[member].gift_cards_total : 0;
                worksheet.getCell(thirdColumn + (row + modifier)).value = giftCardTotals[member] ? (giftCardTotals[member].gift_cards_total): 0;
                worksheet.getCell(fourthColumn + (row + modifier)).value = giftCardTotals[member] ? giftCardTotals[member].roundUps ? giftCardTotals[member].roundUps[activeEvent] : 0 : 0;
                worksheet.getCell(fifthColumn + (row + modifier)).value = weeklyTotals[member] ? weeklyTotals[member].gift_cards_total : 0;
                worksheet.getCell(sixthColumn + (row + modifier)).value = weeklyTotals[member] ? weeklyTotals[member].round_up_total : 0;

                worksheet.getCell(firstColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:memberIndex % 2 === 0 ? 'd3d3d3' : 'ffffff'},
                  };
                worksheet.getCell(secondColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:memberIndex % 2 === 0 ? 'd3d3d3' : 'ffffff'},
                  };
                  worksheet.getCell(thirdColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:memberIndex % 2 === 0 ? 'd3d3d3' : 'ffffff'},
                  };
                  worksheet.getCell(fourthColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:memberIndex % 2 === 0 ? 'd3d3d3' : 'ffffff'},
                  };
                  worksheet.getCell(fifthColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:memberIndex % 2 === 0 ? 'd3d3d3' : 'ffffff'},
                  };
                  worksheet.getCell(sixthColumn + (row + modifier)).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:memberIndex % 2 === 0 ? 'd3d3d3' : 'ffffff'},
                  };

                worksheet.getCell(secondColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
                worksheet.getCell(thirdColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
                worksheet.getCell(thirdColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
                worksheet.getCell(fourthColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
                worksheet.getCell(fifthColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
                worksheet.getCell(sixthColumn + (row + modifier)).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';


                worksheet.getCell(firstColumn + (row + modifier)).border = {
                    left: {style:teamBorderThickness},
                  };
                worksheet.getCell(sixthColumn + (row + modifier)).border = {
                right: {style:teamBorderThickness},
                };
                modifier++;
            })
            worksheet.getCell(firstColumn + (row + modifier-1)).border = {
                bottom: {style:teamBorderThickness},
                left: {style:teamBorderThickness},

              };
              worksheet.getCell(secondColumn + (row + modifier-1)).border = {
                bottom: {style:teamBorderThickness},
              };
              worksheet.getCell(thirdColumn + (row + modifier-1)).border = {
                bottom: {style:teamBorderThickness},
              };
              worksheet.getCell(fourthColumn + (row + modifier-1)).border = {
                bottom: {style:teamBorderThickness},
              };
              worksheet.getCell(fifthColumn + (row + modifier-1)).border = {
                bottom: {style:teamBorderThickness},
              };
              worksheet.getCell(sixthColumn + (row + modifier-1)).border = {
                bottom: {style:teamBorderThickness},
                right: {style:teamBorderThickness},
              };
            worksheet.getCell(firstColumn+row +":"+sixthColumn+(row+modifier)).border = {
                top: {style:teamBorderThickness},
                left: {style:teamBorderThickness},
                bottom: {style:teamBorderThickness},
                right: {style:teamBorderThickness}
              };
            if(index % 2 ===1){
                if(previousModifierLength > modifier){
                    row += previousModifierLength + 1;

                } else {
                    row += modifier + 1;
                }

            } else {
                previousModifierLength = modifier;
            }
            modifier = 1;
        })
        worksheet.pageSetup.orientation = 'landscape';
        worksheet.pageSetup.printArea="A1:M" + (row + 1);
        worksheet.pageSetup.fitToPage = true;

        const buf = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'Team Totals - '+moment().format("MM-DD-YYYY")+'.xlsx')

    }

    return(
        <Box>
            <Typography variant="h3">Gift Card Teams Overview   
            </Typography>
            <Button
                    onClick={()=>{
                        exportGiftCardReport();
                    }}
                >
                    Export
                </Button>
            <Button
                    onClick={()=>{
                        nav("/dashboard/giftcards/teams/modify")
                    }}
                >
                    Modify Teams
                </Button>

            <Grid container spacing={2}>
                {
                    teams.map((team)=>{

                        return(
                            <Grid key={team.id} item lg={6} xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">Team: {team.TeamName}</Typography>

                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                Total
                                            </Grid>
                                            <Grid item xs={3}>
                                                Gift Cards
                                            </Grid>

                                            <Grid item xs={3}>
                                                Round Ups
                                            </Grid>
                                            <Grid item xs={6}>
                                                {teamTotals[team.id] ? formatMoney((teamTotals[team.id].roundUps[activeEvent]+teamTotals[team.id].gift_cards_total), 2, ".", ",") : "$0.00"}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {teamTotals[team.id] ? formatMoney(teamTotals[team.id].gift_cards_total, 2, ",", ".") : "$0.00"}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {teamTotals[team.id] ? teamTotals[team.id].roundUps[activeEvent] ? formatMoney(teamTotals[team.id].roundUps[activeEvent], 2, ",", ".") : "$0.00" : "$0.00"}
                                            </Grid>
                                            <Grid item xs={12}>
                                            <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            Name
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            Total
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            Gift Card Total
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            Round Up Total
                                                        </Grid>
                                                    </Grid>
                                            </Grid>


                                        {
                                                members[team.id] ? members[team.id].map((member)=>{
                                                    return(
                                                        <Grid key={member} item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={3}>
                                                                    {employees[member] ? employees[member].name : ""} 
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    {giftCardTotals[member]  ? giftCardTotals[member].roundUps ? formatMoney((giftCardTotals[member].gift_cards_total + giftCardTotals[member].roundUps[activeEvent]), 2, ".", ",") : giftCardTotals[member].gift_cards_total : 0}
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    {giftCardTotals[member] ? formatMoney(giftCardTotals[member].gift_cards_total, 2, ".", ",") : 0 }
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    {giftCardTotals[member] ? giftCardTotals[member].roundUps ? giftCardTotals[member].roundUps[activeEvent] ? formatMoney(giftCardTotals[member].roundUps[activeEvent], 2, ".", ",") : "$0.00" : "$0.00" : "$0.00"}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }) : ""
                                            }
                                            
                                        </Grid>
  
                                    </CardContent>
                                </Card>

                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}