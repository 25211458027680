import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import { getStartOfWeek } from "../functions";
import moment from "moment-timezone"

export const GiftCardContext = React.createContext({});
export const GiftCardProvider = ({children}) => {
    const [startOfWeek, setStartOfWeek] = useState(getStartOfWeek(moment()));
    const [teams, setTeams] = useState([]);
    const [members, setMembers] = useState({});   
    const [giftCardTotals, setGiftCardTotals] = useState({});
    const [teamTotals, setTeamTotals] = useState({});
    const [weeklyTotals, setWeeklyTotals] = useState({});
    const [activeEvent, setActiveEvent] = useState("jygyg-9978987k-khgkju7j");
    const [events, setEvents] = useState({})
    const [season, setSeason] = useState({});
    const [seasons, setSeasons] = useState([]);
    const url = getApiDomain();

    const loadSeasonOverview = () => {
        return axios.get(url + "/giftcards/season/overview").then((res)=>{
            console.log(res.data);
            let tempSeason = res.data.data.season;
            let tempSeasons = res.data.data.seasons;
            setSeason(res.data.data.season);
            setSeasons(Array.from(tempSeasons))
            return(season);
        })
    }

    const loadDonationEvents = (year) => {
        let completeUrl;
        console.log(year)

        if(season.year){
            completeUrl =  url + "/giftcards/season/events?year="+season.year
        } else if (year !== null){
            completeUrl =  url + "/giftcards/season/events?year="+year

        } else {
            completeUrl = url + "/giftcards/season/events"
        }
        console.log(completeUrl)
        axios.get(url + "/giftcards/season/events").then((res)=>{
            let eventsDict = {};
            if(res.data.data.events){
                res.data.data.events.forEach((event)=>{
                    eventsDict[event.id] = event;
                })
            }
            setEvents({...eventsDict});
        })
    }
    const addDonationEvent = (name, startDate, endDate, included, year) => {
        let event = {
            title: name, 
            startDate,
            endDate,
            included,
            year
        }
        console.log(event)
        axios.post(url + "/giftcards/season/events", {event}).then((res)=>{
            loadDonationEvents(year);
        })
    }
    const addGiftCardSeason = (startDate, endDate, year) => {
        let season = {
            startDate,
            endDate,
            year
        }
        console.log(season)
        axios.post(url + "/giftcards/season/add", {season}).then((res)=>{
            console.log("Loaded season");
            // Reload
        })
    }
    const deleteDonationEvent = (id) => {
        axios.delete(url + "/giftcards/season/events/"+id).then((res)=>{
            loadDonationEvents();
        })
    }
    const loadGiftCardTotals = (year) => {
        let completeUrl;
        console.log(year)
        console.log(season)
        if(season.year){
            
            completeUrl = url + "/giftcards/stats/totals?year="+season.year
        } else if (year){
            completeUrl = url + "/giftcards/stats/totals?year="+year

        } else {
            completeUrl  = url + "/giftcards/stats/totals"
        }
        return axios.get(completeUrl).then((res)=>{
            let tempGGData = {};
            res.data.data.giftCards.forEach((employee)=>{
                tempGGData[employee.employeeId] = employee;
            })
            Object.keys(res.data.data.roundUps).forEach((roundUp)=>{
                res.data.data.roundUps[roundUp].forEach((employee)=>{
                    if(!tempGGData[employee.id]){
                        tempGGData[employee.id] = {
                            gift_cards_total: 0, 
                            round_up_total: 0,
                            other_round_up: 0
                        }
                    }
                    if(!tempGGData[employee.id]["round_up_total"]){
                        tempGGData[employee.id]["round_up_total"] = 0;
                        tempGGData[employee.id]["other_round_up"] = 0;
                    }
                    if(!tempGGData[employee.id].roundUps){
                        tempGGData[employee.id].roundUps = {}
                    }
                    tempGGData[employee.id]["round_up_total"] += employee.round_up_total ? employee.round_up_total : 0;
                    if(roundUp !== activeEvent){
                        tempGGData[employee.id]["other_round_up"] += employee.round_up_total
                    }
                    tempGGData[employee.id].roundUps[roundUp] = employee.round_up_total;
                })
                
            });
            setGiftCardTotals({...tempGGData})
            return(tempGGData)
        })

    }
    const loadWeeklyTotals = () => {
        axios.get(url + "/giftcards/stats/totals/weekly?date="+startOfWeek.format("MM-DD-YYYY")).then((res)=>{
            let tempCurrentTotals = {};
            res.data.data.totals.forEach((total)=>{
                tempCurrentTotals[total.id] = total;
            })
            setWeeklyTotals({...tempCurrentTotals});
            console.log(res.data.data)
        });

    }
    const addEmployees = (teamId, employees) => {
        console.log(teamId)
        axios.post(url + "/giftcards/teams/members", {employees: employees, teamId: teamId}).then((res)=>{
            setMembers(res.data.data.members)
        });
    }
    const deleteMember = (employeeId) => {
        axios.delete(url + "/giftcards/teams/members/"+employeeId).then((res)=>{
            console.log("Successfully added")
            loadTeams();
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const loadTeams = () => {
        axios.get(url + "/giftcards/teams/list").then((res)=>{
            axios.get(url + "/giftcards/teams/members").then((res)=>{
                setMembers(res.data.data.members)
            })
            setTeams(Array.from(res.data.data.teams))
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const addTeam = (team, employeeList) => {
        axios.post(url + "/giftcards/teams/list/"+team, {employees: employeeList}).then((res)=>{
            console.log("Successfully added")
            loadTeams();
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const deleteTeam = (id) => {
        axios.delete(url + "/giftcards/teams/list/"+id).then((res)=>{
            loadTeams();
            console.log("Successfully deleted team");
        }).catch((error)=>{ 
            console.log(error)
        })
    }
    const loadGiftCardData = () => {
        loadSeasonOverview().then((season)=>{
            console.log(season.year)
            loadTeams();
            loadGiftCardTotals(season.year);
            loadWeeklyTotals();
            loadDonationEvents(season.year);
        })
    }
    useEffect(()=>{
        loadGiftCardData();
    }, []);
    useEffect(()=>{
        let tempTeamtotal = {};
        teams.forEach((team)=>{
            if(members[team.id]){

                members[team.id].forEach((member)=>{                    
                    if(!tempTeamtotal[team.id]){
                        tempTeamtotal[team.id] = {
                            round_up_total: 0,
                            gift_cards_total: 0,
                            other_round_up: 0,
                            roundUps: {

                            }
                        }
                    }
                    if(giftCardTotals[member]){
                        tempTeamtotal[team.id].round_up_total += giftCardTotals[member].round_up_total ? giftCardTotals[member].round_up_total : 0;
                        tempTeamtotal[team.id].gift_cards_total += giftCardTotals[member].gift_cards_total ? giftCardTotals[member].gift_cards_total : 0;
                        
                        if(giftCardTotals[member].roundUps){
                        Object.keys(giftCardTotals[member].roundUps).forEach((roundUp)=>{
                            if(!tempTeamtotal[team.id].roundUps[roundUp]){
                                tempTeamtotal[team.id].roundUps[roundUp] = 0;
                            }
                            tempTeamtotal[team.id].roundUps[roundUp] += giftCardTotals[member].roundUps[roundUp];
                            if(activeEvent !== roundUp){
                               tempTeamtotal[team.id].other_round_up += giftCardTotals[member].roundUps[roundUp];
                            }
                        })
                        }
                    }

                })
            }

        });
        setTeamTotals({...tempTeamtotal});
    }, [teams, members, giftCardTotals])
    return(<GiftCardContext.Provider value={{deleteDonationEvent, addDonationEvent, events, weeklyTotals, teams, members, deleteTeam, 
        addTeam, loadTeams, deleteMember, addEmployees, giftCardTotals, loadGiftCardTotals, teamTotals, activeEvent,
        addGiftCardSeason, setActiveEvent, loadSeasonOverview}}>{children}</GiftCardContext.Provider>)
}

export const useGiftCard= ()=>{
    return useContext(GiftCardContext)
}