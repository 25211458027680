import { Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getApiDomain } from "../../../App";
import { useGiftCard } from "../../../context/GiftCards";
import { saveAs } from 'file-saver';
import Excel from "exceljs"
import {
    Route,
    Routes,
    useNavigate
  } from "react-router-dom";
import EmployeeTotalPage from "./EmployeeTotalPage";
import moment from "moment-timezone";
import EmployeeOverviewByDate from "./EmployeeByDate";
const url = getApiDomain();

export default function EmployeeOverview(props) {
    const [servers, setServers] = useState({});
    useEffect(()=>{
        axios.get(url + "/employeesbyschedule/" + "641344900").then((res)=>{
            console.log(res.data.data);
            if(!res.data.data.employees){
                return;
            }
            setServers(Array.from(res.data.data.employees))
        })
    }, []);
    
    return(
        <React.Fragment>
            <Routes>
                <Route index exact element={<EmployeeOverviewByDate employees={servers} />} /> : ""
                <Route path=":id" element={<EmployeeTotalPage />} />
            </Routes>
        </React.Fragment>
    )
}

const EmployeesTable = (props) => {
    const {employees} = {...props};
    let {giftCardTotals, activeEvent} = useGiftCard();
    const nav = useNavigate();
    const exportServerTracker = async () => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Gift Card Totals");
        let outsideBorderThickness = "medium";
        let row = 1;
        worksheet.getCell('A' + row).value = "Gift Card Teams Totals";
        worksheet.getCell('A' + row).style ={
            font: {
                bold: true,
                size: 18
            }
        }
        worksheet.mergeCells('A' + row + ':D'+row)
        worksheet.getCell('A' + row).alignment = {horizontal: 'center'}
        row++;
        worksheet.getCell('A' + row).value = "Updated - " + moment().format("MM/DD/YYYY");
        worksheet.getCell('A' + row).style ={
            font: {
                bold: true,
                size: 12
            }
        }
        worksheet.mergeCells('A' + row + ':D'+row)
        worksheet.getCell('A' + row).alignment = {horizontal: 'center'}
        row++;
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 15;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 15;
        Object.keys(employees).map((key, index)=>{
            const employee = employees[key];
            worksheet.getCell('A' + row).value = employee.name
            worksheet.getCell('B' + row).value = giftCardTotals[employee.id] ? "$" + (giftCardTotals[employee.id].gift_cards_total + giftCardTotals[employee.id].other_round_up + giftCardTotals[employee.id].round_up_total).toFixed(2)  : "$0.00";
            worksheet.getCell('C' + row).value = giftCardTotals[employee.id] ? "$" + (giftCardTotals[employee.id].gift_cards_total).toFixed(2) : "$0.00";
            worksheet.getCell('D' + row).value = giftCardTotals[employee.id] ? "$" + ( giftCardTotals[employee.id].other_round_up + giftCardTotals[employee.id].round_up_total).toFixed(2)  : "$0.00";
            worksheet.getCell('A' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            worksheet.getCell('B' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            worksheet.getCell('C' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            worksheet.getCell('D' + (row)).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb: index % 2 === 0 ? 'd3d3d3' : 'ffffff'},
            };
            row++;
        });
        worksheet.pageSetup.printArea="A1:F" + (row + 1);
        worksheet.pageSetup.fitToPage = true;
        const buf = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'Gift Card Totals - '+moment().format("MM-DD-YYYY")+'.xlsx')

    }
    return(
        <React.Fragment>
            <Typography
                variant="h3"
            >
                Server Overview Totals            
                <Button
                    onClick={()=>{
                        exportServerTracker();
                    }}
                >
                    Export
                </Button>
            </Typography>
            <Grid padding={2} container>
                <Grid
                    item
                    xs={3}
                >
                    Name
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    Total
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    Gift Cards
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    Round Ups
                </Grid>
                {
                    Object.keys(employees).map((key, index)=>{
                        const employee = employees[key];
                        
                        return(
                            <Grid key={employee.id} item xs={12} sx={{background: (index % 2 === 0) ? "#E8E8E8" : "#FFFFFF"}}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Button
                                            sx={{color: "black"}}
                                            onClick={()=>{
                                                nav(employee.id)
                                            }}
                                        >{employee.name}</Button>
                                    </Grid>
                                    <Grid item xs={3}
                                        sx={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {giftCardTotals[employee.id] ? giftCardTotals[employee.id].gift_cards_total ? parseFloat(giftCardTotals[employee.id].gift_cards_total): parseFloat(giftCardTotals[employee.id].gift_cards_total) + parseFloat(giftCardTotals[employee.id].other_round_up)  + parseFloat(giftCardTotals[employee.id].round_up_total) : 0}
                                    </Grid>
                                    <Grid item xs={3}
                                        sx={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {giftCardTotals[employee.id] && isNaN(giftCardTotals[employee.id])  ?  giftCardTotals[employee.id] ? "$" + (giftCardTotals[employee.id].gift_cards_total).toFixed(2) : "$0.00": "$0.00"}
                                    </Grid>
                                    <Grid item xs={3}
                                        sx={{
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {giftCardTotals[employee.id] ? !isNaN((giftCardTotals[employee.id].other_round_up + giftCardTotals[employee.id].round_up_total)) ?  "$" + (giftCardTotals[employee.id].other_round_up + giftCardTotals[employee.id].round_up_total).toFixed(2)  : "$0.00" : "$0.00"} 
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </React.Fragment>
    )
}