import axios from "axios"
import React, { useEffect, useState } from "react"
import moment from "moment-timezone";
import { getApiDomain } from "../../App";
import { Grid, Paper, FormControl, FormControlLabel, Checkbox, FormGroup} from "@mui/material";
import { useHotSchedules } from "../../context/HotSchedules";
import { indexOf } from "lodash";

moment.updateLocale('en', {
    week: {
        dow: 3
    }
});
const url = getApiDomain();
export default function EmployeePayOverview  (props){
    const [payrates, setPayrates] = useState({});
    const {employees, schedules} = useHotSchedules();
    const [filter, setFilter] = useState({
        bake: true,
        boh: true,
        broil: true,
        cold: true,
        dish: true,
        expo: true,
        fry: true,
        hot: true,
        meat: true,
        point: true,
        salad: true,

    });
    let schedule = {
        boh: "1024889472",
        cold: "1024889474",
        hot: "1024889502",
        meat: "1024889504",
        point: "1024889508",
        kitchen: "641344898",
        dish: "641344899",
        expo: "643775092",
        bake: "644758559",
        broil: "644758565",
        fry: "644758573",
        line: "644758575",
        prep: "644758578",
        salad: "644758586"}
    const {bake, boh, broil, cold, dish, expo,fry,hot,meat,point, salad} = filter
    useEffect(()=>{
        axios.get(url + "/employee/payrates").then((res)=>{
            setPayrates(res.data.data);
        })
    }, [])
    console.log(schedules)
    const alphabeticalSort = (a, b)=>{

        if(employees[a].name > employees[b].name) {
            return 1;
        } else if(employees[a].name  < employees[b].name) {
            return -1;
        } else {
            return 0;
        }
    }
    const paySort = (a, b) => {
        if(payrates[a][0].payrate < payrates[b][0].payrate){
            return 1;
        } else if (payrates[a][0].payRate > payrates[b][0].payRate){
            return -1
        } else {
            return 0;
        }
    }
    const handleChange = (event) => { 
        setFilter({
            ...filter,
            [event.target.name]: event.target.checked,
          });
    }
    return(
        <React.Fragment>
            <Paper>
            <FormControl
                sx={{
                    width: 200
                }}
            >
                      <FormGroup aria-label="position" row>

                    <FormControlLabel
                        value="bake"
                        control={<Checkbox 
                            checked={bake} 
                            onChange={handleChange}
                            name="bake"
                        />}
                        label="Bake"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="boh"
                        control={<Checkbox 
                            checked={boh} 
                            onChange={handleChange}
                            name="boh"
                        />}
                        label="BOH Utility"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="broil"
                        control={<Checkbox 
                            checked={broil} 
                            onChange={handleChange}
                            name="broil"
                        />}
                        label="Broil"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="cold"
                        control={<Checkbox 
                            checked={cold} 
                            onChange={handleChange}
                            name="cold"
                        />}
                        label="Cold Prep"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="dish"
                        control={<Checkbox 
                            checked={dish} 
                            onChange={handleChange}
                            name="dish"
                        />}
                        label="Dish"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="expo"
                        control={<Checkbox 
                            checked={expo} 
                            onChange={handleChange}
                            name="expo"
                        />}
                        label="Expo"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="fry"
                        control={<Checkbox 
                            checked={fry} 
                            onChange={handleChange}
                            name="fry"
                        />}
                        label="Fry"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="Hot"
                        control={<Checkbox 
                            checked={hot} 
                            onChange={handleChange}
                            name="hot"
                        />}
                        label="Hot Prep"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="meat"
                        control={<Checkbox 
                            checked={meat} 
                            onChange={handleChange}
                            name="meat"
                        />}
                        label="Meat"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="point"
                        control={<Checkbox 
                            checked={point} 
                            onChange={handleChange}
                            name="point"
                        />}
                        label="point"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="salad"
                        control={<Checkbox 
                            checked={salad} 
                            onChange={handleChange}
                            name="salad"
                        />}
                        label="Salad"
                        labelPlacement="end"
                    />
                </FormGroup>
                </FormControl>
                <Grid container>
                    {Object.keys(payrates).filter((employee)=>{
                        if(!employees[employee]){
                            return false;
                        } else if (employees[employee].active !== 1) {
                            return false;
                        } else {
                            return true;
                        }
                    })
                    .filter((employee)=>{
                        let filteredKeys = Object.keys(filter).map((key)=>{
                            if(filter[key]){
                                return schedule[key]
                            } else {
                                return null
                            }
                        })
                        if(schedules[employee]){
                            console.log(schedules[employee])
                            for(let i = 0; i < schedules[employee].length; i++){
                                console.log(filteredKeys.indexOf(schedules[employee][i]))
                                if(filteredKeys.indexOf(schedules[employee][i]) !== -1 ){
                                    return true
                                }
                            }
                        } else {
                            console.log(employee)
                        }

                        return false;
                    }).
                    sort(paySort).map((employee)=>{return(
                        <React.Fragment key={employee}>
                            <Grid item xs={6}>{employees[employee] ? employees[employee].name : "" }</Grid>
                            <Grid item xs={6}>{payrates[employee][0].payRate}</Grid>

                        </React.Fragment>
                    )
                    })}
                </Grid>
            </Paper>
        </React.Fragment>
    )
}