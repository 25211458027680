/*
    - Triggers to highlight out of wack items.
    - This is just the upload page?
    - Do we have a page that looks at our general problems.

*/

import React, {useEffect, useState} from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import ViewInventory from './ViewInventory';
import { Button, Typography } from '@mui/material';
import axios from "axios";
import { getApiDomain } from '../../../App';
import moment from 'moment-timezone';
import { Route, Routes, useNavigate } from 'react-router';
import TopTwenty from './TopTwenty';
import { InventoryProvider, useInventory } from '../../../context/Inventory';
import InventoryItemDialog from "./InventoryItemDialog";
console.log(`${pdfjsLib.version}`)
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`


const url = getApiDomain();
export default function InventoryOverview(props){
    const {inventory, inventoryDate, setInventoryDate, inventoryArray} = useInventory();
    return(
        <React.Fragment>
          <InventoryProvider>
            <InventoryItemDialog />
            <Routes>
              <Route 
                path=""
                element={
                  <InventoryUpload

                  />
                }
                index
              />
              <Route
                path="toptwenty"
                element={
                  <TopTwenty
                    inventory={inventoryArray}
                  />
                }
              />
            </Routes>
          </InventoryProvider>
        </React.Fragment>
    )
}
const InventoryUpload = (props)=> {
  const {setInventory,setInventoryDate, inventory, inventoryDate} = useInventory();
  const nav = useNavigate();
  const saveInventory = () =>{
    axios.put(url + "/inventory/count", {inventory, inventoryDate: inventoryDate.unix()}).then((res)=>{
      console.log(res.data);
    })
  }
  const handleFifo = function (event) {
    let startDate = "";
    var input = event.target;
    var reader = new FileReader();
    reader.onload = async function (){
      var rawData = new Uint8Array(this.result);
      var loadingTask = await pdfjsLib.getDocument(rawData);
      var lines = {
        "none": []
      };
      var pages = 0;
      var products = {};
      var currentCategory = "none";
      var categories = [];
      var pdfDocument = await loadingTask.promise;
      // Number of pages in the document
      pages = pdfDocument.numPages;

      var page, content, items, varianceInY, itemLineNum, lineNumber;
      
      // Loop through every page of the fifo report
      for(var pageNum = 1; pageNum <=pages; pageNum++){

        page = await pdfDocument.getPage(pageNum);
        
        content = await page.getTextContent();
        // Get the individual items on the PDF.
        items = content.items;

        // Loop through every item.
        items.forEach((item,index)=>{
          //console.log(item)
            // Identitifies a categories
            if(item.transform[5] === 540.072){
              startDate = item.str.split(" ")[0];
            }
          if((item.transform[4] === 37.18 && item.str !== "")){
            categories.push(item.str)
            lines[item.str] = {};
            currentCategory = item.str;
          }
          // Creates variances between lines.
          if(index !== 0){
            varianceInY = item.transform[5] - items[index-1].transform[5];
          } else {
            varianceInY = 0;
            lineNumber = pageNum+""+item.transform[5];
          }

          if(varianceInY < -10 || varianceInY > 10){
            itemLineNum = item.transform[5]
            lineNumber = pageNum+""+ itemLineNum;
          }
          if((varianceInY >=-1 && varianceInY <10) || (varianceInY < -10)){
            if(item.str !== " " && item.str !== ""){
              if(lines[currentCategory][lineNumber]) {
                lines[currentCategory][lineNumber].push(item.str);
              } else {
                lines[currentCategory][lineNumber] = [item.str];
              }
            }
          }
              })
      } 
      var newProduct = {};
      var variances = {
      }
      var actuals = {};
      categories.forEach((cat)=>{
        products[cat] = [];
        let variance = {
          dollar: 0,
          percent: 0
        }
        let actual = {
          onHand: 0,
          costPercent: 0
        }
        Object.keys(lines[cat]).forEach((key)=>{
          var line = lines[cat][key]
          if (line.length === 18 && line[1].indexOf("Copyright") === -1 && line[1].indexOf("FUEL SURCHARGE") === -1 && line[1].indexOf("DELIVERY CHARGE") === -1 && line[1].indexOf("Sales Tax for Cat") === -1 && line[1].indexOf("Fiscal Year:") === -1){
            newProduct = {
              itemId: line[2],
              name: line[1],
              units: line[3],
              purInventory: parseFloat(line[5]),
              endInventory: parseFloat(line[0]),
              costPerUnit: parseFloat(line[6].replace("(", "-").replace(")", "").replace("$", "")),
              endingValue: parseFloat(line[7].replace("(", "-").replace(")", "").replace("$", "")),
              daysOnHand: parseFloat(line[8].replace("(", "-").replace(")", "")),
              usageActual: parseFloat(line[9].replace("(", "-").replace(")", "")),
              usageTheory: parseFloat(line[10].replace("(", "-").replace(")", "")),
              usageVariance: parseFloat(line[11].replace("(", "-").replace(")", "")),
              dollarActual: parseFloat(line[12].replace("(", "-").replace(")", "").replace("$", "")),
              dollarTheory: parseFloat(line[13].replace("(", "-").replace(")", "").replace("$", "")),
              dollarVariance: parseFloat(line[14].replace("(", "-").replace(")", "").replace("$", "")),
              percentActual: parseFloat(line[15].replace("(", "-").replace(")", "").replace("%", "")),
              percentTheory: parseFloat(line[16].replace("(", "-").replace(")", "").replace("%", "")),
              percentVariance: parseFloat(line[17].replace("(", "-").replace(")", "").replace("%", ""))

            }
            variance.dollar += newProduct.dollarVariance;
            variance.percent += newProduct.percentVariance;
            actual.onHand += newProduct.endingValue;
            actual.costPercent += newProduct.percentActual;
            products[cat].push(newProduct);

          }
        })
        variances[cat] = variance;
        actuals[cat] = actual;
      })
    
      setInventory(products);
      setInventoryDate(moment(startDate, "MM/DD/YYYY").startOf('day'))
      //setInventoryData(products);
    }
    reader.readAsArrayBuffer(input.files[0]);

  }
  return(
    <React.Fragment>
    <Typography>
      {inventoryDate ? ("Inventory Overview - " + inventoryDate.format("MM-DD-YYYY")) : "Inventory Overview"}
    </Typography>
    <Button
      onClick={saveInventory}
    >
      Save
    </Button>
    <Button
      onClick={()=>{
        nav("../toptwenty")
      }}
    >
      Top Twenty
    </Button>
    <FileInput handleFifo = {handleFifo}/>
    <ViewInventory inventory={inventory} />
</React.Fragment>
  )
}
const FileInput = ({handleFifo}) => {
    return (
      <input type='file' accept="application/pdf" onChange={(e)=>handleFifo(e)} />
    )
  }