import { Dialog, AppBar, Toolbar, IconButton, DialogContent, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useInventory } from "../../../context/Inventory";
import CloseIcon from '@mui/icons-material/Close';
import { formatMoney } from "../../../functions";
import moment from "moment-timezone";


export default function IventoryItemDialog(props){
    const {openItem, activeItem, closeItemDialog, inventoryArray, previousInventory, inventoryDate} = useInventory();
    const [activeIndividual, setActiveIndividual] = useState(null);
    useEffect(()=>{
        setActiveIndividual(inventoryArray[activeItem])
    }, [activeItem])
    return(
        <Dialog
            fullScreen
            open={openItem}
            onClose={
                closeItemDialog
            }
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={closeItemDialog}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Typography variant="h5">{activeIndividual ? activeIndividual.name : ""}</Typography>
            <Typography>{activeIndividual ? ("Units - " + activeIndividual.units) : ""}</Typography>
            <br />
            <Grid container>
                <Grid item xs={1}>
                    Date
                </Grid>
                <Grid item xs={1}>
                    Theory Usage
                </Grid>
                <Grid item xs={2}>
                    Actual Usage
                </Grid>
                <Grid item xs={2}>
                    Variance Usage
                </Grid>
                <Grid item xs={2}>
                    Dollar Usage
                </Grid>
                <Grid item xs={2}>
                    Actual Dollar Usage
                </Grid>
                <Grid item xs={2}>
                    Variance Dollar Usage
                </Grid>
                <Grid item xs={1}>
                    {!inventoryArray[activeItem] ? "" : inventoryDate.format("MM-DD-YYYY")}
                </Grid>
                <Grid item xs={1}>
                    {!inventoryArray[activeItem] ? "" : inventoryArray[activeItem].usageTheory}
                </Grid>
                <Grid item xs={2}>
                    {!inventoryArray[activeItem] ? "" : inventoryArray[activeItem].usageActual}
                </Grid>
                <Grid item xs={2}>
                    {!inventoryArray[activeItem] ? "" : inventoryArray[activeItem].usageVariance}
                </Grid>
                <Grid item xs={2}>
                    {!inventoryArray[activeItem] ? "" : formatMoney((inventoryArray[activeItem].dollarTheory), 2, ".", ",")}
                </Grid>
                <Grid item xs={2}>
                    {!inventoryArray[activeItem] ? "" : formatMoney((inventoryArray[activeItem].dollarActual), 2, ".", ",")}
                </Grid>
                <Grid item xs={2}>
                    {!inventoryArray[activeItem] ? "" : formatMoney((inventoryArray[activeItem].dollarVariance), 2, ".", ",")}
                </Grid>
                {
                    
                    activeItem && previousInventory[activeItem] ? previousInventory[activeItem].sort((a, b)=>{
                        return a.date > b.date ? -1 : 1;
                    }).map((item)=>{

                        return(
                            <React.Fragment>
                                <Grid item xs={1}>
                                    {moment.unix(item.date).format("MM-DD-YYYY")}
                                </Grid>
                                <Grid item xs={1}>
                                    {item.usageTheory}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.usageActual}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.usageVariance}
                                </Grid>
                                <Grid item xs={2}>
                                    {formatMoney((item.dollarTheory), 2, ".", ",")}
                                </Grid>
                                <Grid item xs={2}>
                                    {formatMoney((item.dollarActual), 2, ".", ",")}
                                </Grid>
                                <Grid item xs={2}>
                                    {formatMoney((item.dollarVariance), 2, ".", ",")}
                                </Grid>
                            </React.Fragment>
                        )
                    }) : ""
                }
            </Grid>
        </DialogContent>
        </Dialog>
    )
}