import { Dialog, DialogActions, DialogContent, Typography, Grid, Toolbar, IconButton} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams} from "react-router";
import { useGiftCard } from "../../../context/GiftCards";
import { useHotSchedules } from "../../../context/HotSchedules";
import { getApiDomain } from "../../../App";
import moment from "moment-timezone"
const url = getApiDomain();


export default function EmployeeTotalPage(props){
    const {id} = useParams();
    const {employees} = useHotSchedules();
    const nav = useNavigate();
    const [foodRunningData, setFoodRunningData] = useState([]);
    const {giftCardTotals} = useGiftCard();
    useEffect(()=>{
        if(id){
            console.log(url + "/giftcards/stats/foodrunning/"+id);
            axios.get(url + "/giftcards/stats/foodrunning/"+id).then((res)=>{
                console.log(res.data.data.foodrunning);
                setFoodRunningData(res.data.data.foodrunning)
            });
        }
    }, [id])
    return(
        <React.Fragment>
            {
            employees[id] ? 
                <React.Fragment>
                    <Toolbar>
                        <IconButton
                            size="large"
                            aria-label="back"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={()=>{
                                nav(-1)
                            }}
                            color="inherit"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h5">{employees[id].name}</Typography>
                    </Toolbar>
                    <Grid container>
                        <Grid item xs={4}>
                            Date
                        </Grid>
                        <Grid item xs={4}>
                            Gift Cards
                        </Grid>
                        <Grid item xs={4}>
                            Round Ups
                        </Grid>
                        {foodRunningData.sort((a, b)=>{
                            return(a > b);
                        }).map((dataPoint, index)=>{
                            return(
                                <Grid item xs={12} key={dataPoint.id} sx={{background: (index % 2 === 0) ? "#E8E8E8" : "#FFFFFF"}}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {moment.unix(dataPoint.date).format("MM-DD-YYYY")}
                                        </Grid>
                                        <Grid item xs={4}>
                                            ${dataPoint.gift_cards.toFixed(0)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            ${dataPoint.round_ups.toFixed(0)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                             );
                        })}
                    </Grid>
                </React.Fragment>
                : "Invalid employee"
            }
        </React.Fragment>
    )
}