import { Paper, Typography, IconButton, Toolbar, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone"
import { getApiDomain, getOvertimeTrackerApi } from "../../App";
import { useHotSchedules } from "../../context/HotSchedules";
import { Add } from "@mui/icons-material";
import RequestOffPopOver from "./RequestOffPopOver";
import { getStartOfWeek } from "../../functions";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BlockIcon from '@mui/icons-material/Block';
import BlockedDayPopover from "./BlockedDayPopover";
const url = getApiDomain();
const overtimeTrackerUrl = getOvertimeTrackerApi();
const roles = {
    "1024889472": "BOH Utility",
    "1024889474": "Cold Prep",
    "1024889502": "Hot Prep",
    "1024889504": "Meat Cut",
    "1024889508": "Point",
    "641344898": "Kitchen ",
    "641344899": "Dish",
    "643775091": "Train BOH",
    "643775092": "Expo",
    "644758559": "Bakery",
    "644758565": "Broil",
    "644758573": "Fry",
    "644758575": "Line",
    "644758578": "Prep",
    "644758586": "Salad",
};
export default function RequestOffDashboard () {
    const [requestOffs, setRequestOffs] = useState([]);
    const {employees, schedules, loading} = useHotSchedules();
    const [open, setOpen] = useState(false);
    const [blockedDayOpen, setBlockedDayOpen] = useState(false);
    const [weekList, setWeekList] = useState({});
    const [selectedDate, setSelectedDate] = useState(getStartOfWeek(moment()));
    const [startofWeek, setStartOfWeek] = useState(getStartOfWeek(moment()));

    const handleBlockedDayDialog = ()=>{
        setBlockedDayOpen(!blockedDayOpen);
    }
    const handleRequestOffDialog = ()=>{
        setOpen(!open);
    }
    const loadRequestoffs = async (startDate, endDate)=> {
        console.log(overtimeTrackerUrl + "/json/requestoffs?startDate="+startDate+"&endDate="+endDate)
        let data = await axios.get(overtimeTrackerUrl + "/json/requestoffs?startDate="+startDate+"&endDate="+endDate);
        data = data.data;
        return data
    }
    const processRequestOffs = async (tempStartOfWeek) => {
        let tempRequestOffs = await loadRequestoffs(tempStartOfWeek.format("YYYY-MM-DD"), moment(tempStartOfWeek).add(6, "days").format("YYYY-MM-DD"));
        let tempRequestWeekList = {}
        console.log(tempRequestOffs);
        tempRequestOffs.forEach((request)=>{
            let currentDate = moment(request.startDateTime);

            let startDate = moment(request.startDateTime).startOf("day");
            let endDate = moment(request.endDateTime).startOf("day");
            let days = endDate.diff(startDate, "days");
            if(days>1){
                for(let i = 0; i < days; i++){
                    if(tempRequestWeekList[currentDate.format("MM-DD-YYYY")]){
                        tempRequestWeekList[currentDate.format("MM-DD-YYYY")].push({
                            name: employees[request.employeeId] ? employees[request.employeeId].name : "",
                            employeeId: request.employeeId
                        })
                    } else {
                        tempRequestWeekList[currentDate.format("MM-DD-YYYY")] = [];
                        tempRequestWeekList[currentDate.format("MM-DD-YYYY")].push({
                            name: employees[request.employeeId] ? employees[request.employeeId].name : "",
                            employeeId: request.employeeId
                        })
                    }
                    currentDate.add(1, "days");
                }
            } else {
                if(tempRequestWeekList[currentDate.format("MM-DD-YYYY")]){
                    tempRequestWeekList[currentDate.format("MM-DD-YYYY")].push({
                        name: employees[request.employeeId] ? employees[request.employeeId].name : "",
                        employeeId: request.employeeId
                    })
                } else {
                    tempRequestWeekList[currentDate.format("MM-DD-YYYY")] = [];
                    tempRequestWeekList[currentDate.format("MM-DD-YYYY")].push({
                        name: employees[request.employeeId] ? employees[request.employeeId].name : "",
                        employeeId: request.employeeId
                    })
                }
            }


        })
        console.log(tempRequestWeekList)
        setRequestOffs(Array.from(tempRequestOffs));
        setWeekList({...tempRequestWeekList})
    }
    useEffect(async ()=>{
        if(!loading){
            processRequestOffs(startofWeek);
        }

    }, [loading])
    const handleSelectedDateChange = async (newValue) => {
        setSelectedDate(moment(newValue).startOf("day"));
        setStartOfWeek(moment(newValue).startOf("day"));
        processRequestOffs(moment(newValue).startOf("day"));

    }
    if(loading){
        return(
            <React.Fragment>
                Loading
            </React.Fragment>
        )
    } else {

    return(
        <Paper>
                <RequestOffPopOver open={open} setOpen={setOpen} /> 
                <BlockedDayPopover open={blockedDayOpen} setOpen={setBlockedDayOpen} />
              <Toolbar position="static" color="secondary">
                    <Typography>
                        Request Off Dashboard 

                        <IconButton onClick={handleRequestOffDialog}aria-label="new">
                            <Add />
                        </IconButton>
                        <IconButton onClick={handleBlockedDayDialog}aria-label="new">
                            <BlockIcon />
                        </IconButton>
                    </Typography>

                </Toolbar>
                <Grid container>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                label="Date"
                                inputFormat="MM/DD/YYYY"
                                value={selectedDate}
                                onChange={handleSelectedDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            </LocalizationProvider>
                    </Grid>
                {

                    Array.from({ length: 7 }).map((it, index)=>{
                        let tempStartOfWeek = moment(startofWeek);
                        tempStartOfWeek.add(index, "day");
                        return(
                            <React.Fragment>
                                <Grid item key={index} xs={12} md={6} >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">{tempStartOfWeek.format("LL")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">Front of House</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            {
                                                                weekList[tempStartOfWeek.format("MM-DD-YYYY")] ? weekList[tempStartOfWeek.format("MM-DD-YYYY")].filter((employee)=>{
                                                                    const rolesKeys = Object.keys(roles)
                                                                    for(let x = 0; x<Object.keys(roles).length; x++){
                                                                        if(schedules[employee.employeeId]){
                                                                            return schedules[employee.employeeId].indexOf(rolesKeys[x]) === -1 
                                                                        }
                                                                    }
                                                                    if(!schedules[employee.employeeId])
                                                                    {
                                                                        return false
                                                                    }
                                                                }).map((employee)=>{
                                                                    return(
                                                                        <React.Fragment>
                                                                            <Grid item xs={12}>
                                                                                {employee.name}
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )
                                                                }) : ""
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">Back of House</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            {
                                                                weekList[tempStartOfWeek.format("MM-DD-YYYY")] ? weekList[tempStartOfWeek.format("MM-DD-YYYY")].filter((employee)=>{
                                                                    const rolesKeys = Object.keys(roles)
                                                                    for(let x = 0; x<Object.keys(roles).length; x++){
                                                                        if(schedules[employee.employeeId]){
                                                                            return schedules[employee.employeeId].indexOf(rolesKeys[x]) !== -1  
                                                                        }   
                                                                    }
                                                                    if(!schedules[employee.employeeId])
                                                                    {
                                                                        return false
                                                                    }
                                                                }).map((employee)=>{
                                                                    return(
                                                                        <React.Fragment>
                                                                            <Grid item xs={12}>
                                                                                {employee.name}
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )
                                                                }) : ""
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                                        
                    })
                }
                </Grid>
        </Paper>
    )
    }
}


