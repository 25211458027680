import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Container, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ViewInventory = ({inventory}) => {
    let products = {}
    return(
        <Container>
            {
                (inventory) ? 
                Object.keys(inventory).map((cat)=>{
                    products = inventory[cat];
                    return(<Category key={cat}category={cat} products={products} /> );
                })
                : "No items to display"
            }   

        </Container>
    );
}

const Category = ({category, products}) => {
    
    return(
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={category+"-content"}
                id={category}
            >
                <Typography>
                    {category}
                </Typography>
            </AccordionSummary>
  
            <AccordionDetails>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={2}>
                                Item Name
                            </Grid>
                            <Grid item xs={2}>
                                Unit
                            </Grid>
                            <Grid item xs={2}>
                                On Hand
                            </Grid>
                            <Grid item xs={2}>
                                Theory Usage
                            </Grid>
                            <Grid item xs={2}>
                                Variance by unit
                            </Grid>
                            <Grid item xs={2}>
                                Variance by dollar
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        products.map((product)=>{
                            return (<Product key={product.id} product={product} />)
                        })
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

const Product = ({product}) => {
    return(
        <Grid container>
            <Grid item xs = {2}>
               {product.name} 
            </Grid>
            <Grid item xs = {2}>
                {product.units}
            </Grid>
            <Grid item xs={2}>
                {product.endInventory}
            </Grid>
            <Grid item xs={2}>
                {product.usageTheory}
            </Grid>
            <Grid item xs={2}>
                {product.usageVariance}
            </Grid>
            <Grid item xs={2}>
                {product.dollarVariance}
            </Grid>
    </Grid>
    )
}

export default ViewInventory