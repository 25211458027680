import React, { useEffect, useState } from "react";
import moment from "moment-timezone"
import { getApiDomain, getOvertimeTrackerApi } from "../../App";
import { useHotSchedules } from "../../context/HotSchedules";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BlockIcon from '@mui/icons-material/Block';
import axios from "axios";
const overtimeTrackerUrl = getOvertimeTrackerApi();


export default function RequestOfPopOver(props){
    const {closeCallBack, open, setOpen} = props;
    const [message, setMessage] = useState("");
    const handleClose = () => {
        setOpen(false)
        setMessage("")
        if(closeCallBack){
            closeCallBack();
        }
    }
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [note, setNote]= useState("");
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
        if(moment(newValue).isAfter(endDate)){
            setEndDate(newValue);
        }
    }
    const handleEndDateChange = (newValue) => {
        setEndDate(newValue)
        if(moment(newValue).isBefore(startDate)){
            setStartDate(newValue);
        }
    }
    const handleSubmit = async () =>{
        // TODO: Make a dynamic blocked by id
        console.log(endDate)
        let data = {
            blockedBy: 83064465,
            startDate: startDate.startOf("day").format(),
            endDate: moment(endDate).add(1, "day").startOf("day").format(),
            note: note
        }
        //data = JSON.stringify(data);
        axios.post(overtimeTrackerUrl + '/json/createblockedday', data).then((res)=>{
            console.log(res.data)
            if(res.status === 400){
                console.log("Error")
                setMessage("Error submitting try again")
            } else {
                handleClose();
            }

        })
        .catch((error)=>{
            console.log("Error")
            setMessage("Error submitting try again")
        })
        console.log("Submitting")

    }
    return(
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Create a blocked day
                </DialogTitle>
                    <DialogContent>
                        {message}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <p />
                            <MobileDatePicker
                                label="Start Date"
                                inputFormat="MM/DD/YYYY"
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <p />
                            <MobileDatePicker
                                label="End Date"
                                inputFormat="MM/DD/YYYY"
                                value={endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            </LocalizationProvider>
                            <br />
                            <TextField id="note" label="Reason" variant="standard" onChange={(event)=>{
                                setNote(event.target.value)
                            }} />

                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>.
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}