import { Box, Grid, Typography, IconButton, Dialog, DialogContent, TextField, DialogTitle, Stack, Button, FormControlLabel, Checkbox } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getApiDomain } from "../../../App";
import moment from "moment-timezone";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useGiftCard } from "../../../context/GiftCards";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const url = getApiDomain();


export default function SeasonOverview(props){
    const {events, deleteDonationEvent, loadDonationEvents} = useGiftCard();
    const [season, setSeason] = useState({});
    const [donationDialog, setDonationDialog] = useState(false);
    const [seasonDialog, setSeasonDialog] = useState(false);

    const [modify, setModify] = useState(false);
    const handleDialog = () => {
        setDonationDialog(!donationDialog);
    }
    const handleSeason = () => {
        setSeasonDialog(!seasonDialog)
    }

    useEffect(()=>{
        axios.get(url + "/giftcards/season/overview").then((res)=>{
            console.log(res.data);
            setSeason(res.data.data.season);
        }, [])

    }, [])
    return(
        <React.Fragment>
            <CreateYearDialog open={seasonDialog} handleClose={handleSeason} />
            <CreateDonationDialog open={donationDialog} handleClose={handleDialog} />
            <Box>
                <Typography variant="h3">Season Overview                 <IconButton
                                size="large"
                                aria-label="Add"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=>{
                                    handleSeason();
                                }}
                                color="inherit"
                            >
                                <ControlPointIcon />
                            </IconButton></Typography>

                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="h5">Dates</Typography>
                        Start Date: {season['startDate'] ? moment.unix(season.startDate).format("MM-DD-YYYY") : ""}
                        <br />
                        End Date: {season['endDate'] ? moment.unix(season.endDate).format("MM-DD-YYYY") : ""}

                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5">Donations Events                         
                            <IconButton
                                size="large"
                                aria-label="Add"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=>{
                                    handleDialog();
                                }}
                                color="inherit"
                            >
                                <ControlPointIcon />
                            </IconButton>
                            <IconButton
                                size="large"
                                aria-label="edit"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=>{
                                    setModify(!modify);
                                }}
                                color="inherit"
                            >
                                <ModeEditIcon />
                            </IconButton>
                        </Typography>
                        {
                            Object.keys(events).map((key)=>{
                                const event = events[key];
                                return(
                                    <React.Fragment key={event.id}>
                                        {event.name}
                                        {modify ?  
                                        <IconButton
                                            size="small"
                                            aria-label="edit"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={()=>{
                                                deleteDonationEvent(key);
                                            }}
                                            color="inherit"
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                        : ""
                                        }
                                        <br />
                                    </React.Fragment>)
                            })
                        }
                    </Grid>
                </Grid>

            </Box>
        </React.Fragment>
    )
}


const CreateDonationDialog = (props) => {
    const {addDonationEvent} = useGiftCard();
    const {open, handleClose, activeEvent} = {...props};
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const checkRef = useRef();
    const titleRef = useRef();
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    }
    const handleEndDateChange = (newValue) => {
        setEndDate(newValue)
    }
    const handleSubmit = () => {
        let included = checkRef.current.checked ? 1 : 0;
        addDonationEvent(titleRef.current.value, startDate.startOf('day').unix(), endDate.startOf('day').unix(), included, startDate.year())
        console.log(checkRef.current.checked)
        handleClose();
    }
    return(
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Donation Event
                </DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={3}
                    >
                    <TextField
                        inputRef={titleRef}
                        label="Title"
                    />
                    <LocalizationProvider dateAdapter={AdapterMoment}>

                        <MobileDatePicker
                            label="Start Date"
                            inputFormat="MM/DD/YYYY"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <MobileDatePicker
                            label="End Date"
                            inputFormat="MM/DD/YYYY"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                inputRef={checkRef}
                            />
                        }
                        label="Include in Gift Card Sales"
                    />
                </Stack>
                <Button
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

const CreateYearDialog = (props) => {
    const {addGiftCardSeason} = useGiftCard();
    const {open, handleClose, activeEvent} = {...props};
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const checkRef = useRef();
    const titleRef = useRef();
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    }
    const handleEndDateChange = (newValue) => {
        setEndDate(newValue)
    }
    const handleSubmit = () => {
        addGiftCardSeason(startDate.startOf('day').unix(), endDate.startOf('day').unix(), startDate.year())
        console.log(checkRef.current.checked)
        handleClose();
    }
    return(
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Gift Card Season
                </DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={3}
                    >
                    <LocalizationProvider dateAdapter={AdapterMoment}>

                        <MobileDatePicker
                            label="Start Date"
                            inputFormat="MM/DD/YYYY"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <MobileDatePicker
                            label="End Date"
                            inputFormat="MM/DD/YYYY"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Stack>
                <Button
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}