import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import React, {useState} from "react"
import Excel from "exceljs"
import axios from "axios";
import { useHotSchedules } from "../../context/HotSchedules";
import { getApiDomain, getOvertimeTrackerApi } from "../../App";
import { TrySharp } from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function ImportBlast(props){
    const [currentWeek, setCurrentWeek] = useState("");
    const [serverBlast, setServerBlast] = useState({});
    const [periodDict, setPeriodDict] = useState({
        period: 0,
        week: 0
    })
    const {getServerIdByName} = useHotSchedules();
    const nav = useNavigate();
    const handleImport = (event) => {
        var input = event.target;
        var reader = new FileReader();
        var workbook = new Excel.Workbook();
        reader.onload = async function (){
            var rawData = new Uint8Array(this.result);


            await workbook.xlsx.load(rawData);
            let worksheet = workbook.getWorksheet("BLAST Report_Store");
            try{
                if(!worksheet){
                    console.log("PowerBI")
                    worksheet = workbook.getWorksheet("BLAST Report");
                    handlePowerBIImport(worksheet);
                } else {
                    handleEmailImport(worksheet);
                }
            } catch (error) {
                console.log(error)
            }


        }
        reader.readAsArrayBuffer(input.files[0]);
    }
    const handleEmailImport = async (worksheet) => {
        let rowStart = 19;
        let currentCell = 21;
        console.log(worksheet.getCell("C"+rowStart).value);
        let periodText = worksheet.getCell("C" + rowStart).value;
        periodText = periodText.split("-");
        let period = periodText[1].split("P")[1];
        let week = periodText[2].split("W")[1];
        let tempPeriodDict = {
            period: period,
            week: week
        }
        console.log(period + " " + week);
        let tempServerBlast = {};
        let errorServersBlast= {}
        console.log(worksheet.getCell("A"+currentCell).value)
        while(worksheet.getCell("A"+currentCell).value !== null){
            let serverId = getServerIdByName(worksheet.getCell("A"+currentCell).value)
            if(serverId){
                tempServerBlast[serverId]={
                    name: worksheet.getCell("A"+currentCell).value,
                    ppa: worksheet.getCell("D"+currentCell).value !== null ? worksheet.getCell("D"+currentCell).value : 0,
                    loaded: worksheet.getCell("E"+currentCell).value !== null ? worksheet.getCell("E"+currentCell).value : 0,
                    app: worksheet.getCell("F"+currentCell).value !== null ? worksheet.getCell("F"+currentCell).value : 0,
                    smother: worksheet.getCell("G"+currentCell).value !== null ? worksheet.getCell("G"+currentCell).value : 0,
                    sidekick: worksheet.getCell("H"+currentCell).value !== null ? worksheet.getCell("H"+currentCell).value : 0,
                    total: worksheet.getCell("I"+currentCell).value !== null ? worksheet.getCell("I"+currentCell).value : 0,


                }
            } else {
                errorServersBlast[worksheet.getCell("A"+currentCell).value]={
                    name: worksheet.getCell("A"+currentCell).value,
                    ppa: worksheet.getCell("D"+currentCell).value !== null ? worksheet.getCell("D"+currentCell).value : 0,
                    loaded: worksheet.getCell("E"+currentCell).value !== null ? worksheet.getCell("E"+currentCell).value : 0,
                    app: worksheet.getCell("F"+currentCell).value !== null ? worksheet.getCell("F"+currentCell).value : 0,
                    smother: worksheet.getCell("G"+currentCell).value !== null ? worksheet.getCell("G"+currentCell).value : 0,
                    sidekick: worksheet.getCell("H"+currentCell).value !== null ? worksheet.getCell("H"+currentCell).value : 0,
                    total: worksheet.getCell("I"+currentCell).value !== null ? worksheet.getCell("I"+currentCell).value : 0,

                }
            }
            currentCell++;
        }
        setServerBlast({...tempServerBlast});
        setCurrentWeek(worksheet.getCell("C"+rowStart).value);
        setPeriodDict({...tempPeriodDict});
    }
    const handlePowerBIImport = async (worksheet) => {
        let rowStart = 18;
        let currentCell = 20;


        console.log(worksheet.getCell("G"+rowStart).value);
        let periodText = worksheet.getCell("G" + rowStart).value;
        periodText = periodText.split("-");
        let period = periodText[1].split("P")[1];
        let week = periodText[2].split("W")[1];
        let tempPeriodDict = {
            period: period,
            week: week
        }
        console.log(period + " " + week);
        let tempServerBlast = {};
        let errorServersBlast= {}
        console.log(worksheet.getCell("B"+currentCell).value)
        while(worksheet.getCell("B"+currentCell).value !== null){
            let serverId = getServerIdByName(worksheet.getCell("B"+currentCell).value)
            if(serverId){
                tempServerBlast[serverId]={
                    name: worksheet.getCell("B"+currentCell).value,
                    ppa: worksheet.getCell("G"+currentCell).value !== null ? worksheet.getCell("G"+currentCell).value : 0,
                    loaded: worksheet.getCell("I"+currentCell).value !== null ? worksheet.getCell("I"+currentCell).value : 0,
                    app: worksheet.getCell("K"+currentCell).value !== null ? worksheet.getCell("K"+currentCell).value : 0,
                    smother: worksheet.getCell("M"+currentCell).value !== null ? worksheet.getCell("M"+currentCell).value : 0,
                    sidekick: worksheet.getCell("O"+currentCell).value !== null ? worksheet.getCell("O"+currentCell).value : 0,
                    total: worksheet.getCell("Q"+currentCell).value !== null ? worksheet.getCell("Q"+currentCell).value : 0,

                }
            } else {
                errorServersBlast[worksheet.getCell("B"+currentCell).value]={
                    name: worksheet.getCell("B"+currentCell).value,
                    ppa: worksheet.getCell("G"+currentCell).value !== null ? worksheet.getCell("G"+currentCell).value : 0,
                    loaded: worksheet.getCell("I"+currentCell).value !== null ? worksheet.getCell("I"+currentCell).value : 0,
                    app: worksheet.getCell("K"+currentCell).value !== null ? worksheet.getCell("K"+currentCell).value : 0,
                    smother: worksheet.getCell("M"+currentCell).value !== null ? worksheet.getCell("M"+currentCell).value : 0,
                    sidekick: worksheet.getCell("O"+currentCell).value !== null ? worksheet.getCell("O"+currentCell).value : 0,
                    total: worksheet.getCell("Q"+currentCell).value !== null ? worksheet.getCell("Q"+currentCell).value : 0,

                }
            }
            currentCell++;
        }
        setServerBlast({...tempServerBlast});
        setCurrentWeek(worksheet.getCell("G"+rowStart).value);
        setPeriodDict({...tempPeriodDict});
        
    }
    const sendBlast = () => {
        console.log(getOvertimeTrackerApi() + "/json/blastupdate")
        axios.post(getOvertimeTrackerApi() + "/json/blastupdate", {serverBlast: serverBlast, period: periodDict.period, week: periodDict.week}).then((res)=>{
            console.log("Sent")
            nav("/dashboard/foh/serverlist")
        }).catch((error)=>{
            console.log("Error")
        })
    }
        return(
        <React.Fragment>
            <Paper>
                <Typography
                    variant="h5"
                >
                    Import Blast
                </Typography>

                <input type='file' accept="application/xlsx" onChange={(e)=>handleImport(e)} />
                <Button
                    onClick={
                        ()=>{
                            sendBlast()
                        }
                    }
                >
                    Save
                </Button>
                <Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>
                                {currentWeek}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {
                                    Object.keys(serverBlast).map((key)=>{
                                        return(
                                            <React.Fragment>
                                                <Grid item xs={4}>
                                                    {serverBlast[key].name}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {Intl.NumberFormat('en-us').format(serverBlast[key].ppa)}
                                                </Grid> 
                                                <Grid item xs={1}>
                                                    {Intl.NumberFormat('en-us').format(serverBlast[key].loaded)}
                                                </Grid>   
                                                <Grid item xs={1}>
                                                    {Intl.NumberFormat('en-us').format(serverBlast[key].app)}
                                                </Grid> 
                                                <Grid item xs={1}>
                                                    {Intl.NumberFormat('en-us').format(serverBlast[key].smother)}
                                                </Grid> 
                                                <Grid item xs={1}>
                                                    {Intl.NumberFormat('en-us').format(serverBlast[key].sidekick)}
                                                </Grid> 
                                                <Grid item xs={1}>
                                                    {Intl.NumberFormat('en-us').format(serverBlast[key].total)}
                                                </Grid> 
                                            </React.Fragment>
                                        )

                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </React.Fragment>
    )
}