import React, {useEffect, useState} from 'react';
import axios from "axios";
import { Snackbar, Box, IconButton,Typography, Grid, Card } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getApiDomain } from "../../App";
import moment from 'moment-timezone';
import {getStartOfWeek, formatMoney} from "../../functions";
import SyncIcon from '@mui/icons-material/Sync';
import { HorizontalRule } from '@mui/icons-material';

moment.updateLocale('en', {
    week: {
        dow: 3
    }
});
const url = getApiDomain();
const roles = {
    "1024889472": "BOH Utility",
    "1024889474": "Cold Prep",
    "1024889502": "Hot Prep",
    "1024889504": "Meat Cut",
    "1024889508": "Point",
    "641344898": "Kitchen ",
    "641344899": "Dish",
    "643775091": "Train BOH",
    "643775092": "Expo",
    "644758559": "Bakery",
    "644758565": "Broil",
    "644758573": "Fry",
    "644758575": "Line",
    "644758578": "Prep",
    "644758586": "Salad",
};
export default function ScheduleOverview (props){
    const [startOfWeek, setStartOfWeek] = useState(getStartOfWeek(moment()));
    const [currentDay, setCurrentDay] = useState(getStartOfWeek(moment()).startOf("day"));
    const [fiscalInfo, setFiscalInfo] = useState({});
    const [salesData, setSalesData] = useState({});
    const [totalMinutes, setTotalMinutes] = useState({});
    const [totalCost, setTotalCost] = useState({});
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarState, setSnackbarState] = useState(false);
    const [weeklyTotalData, setWeeklyTotalData] = useState({
        bohHours: 0,
        bohDollars: 0,
        ovtHours: 0,
        ovtCost: 0,
        trainingHours: 0,
        trainingDollars: 0,
        totalBohDollars: 0,
        totalBohHours: 0,
        managerKeyHours: 0,
        managerKeyDollars: 0,
    });
    const [weeklySalesData, setWeeklySalesData] = useState({
        guests: 0,
        sales: 0,
    });
    const [teamsheets, setTeamsheet] = useState({});
    const [employees, setEmployees] = useState({});
    const [locations, setLocations] = useState({});
    const [jobcodes, setJobcodes] = useState({});
    const [projectedSalesGrowth, setProjectedSalesGrowth] = useState(1.03);
    const [projectedGuestGrowth, setProjectedGuestGrowth] = useState(1.01);
    const salesForLabor = [145000, 150000, 155000, 160000, 165000, 170000, 175000, 180000, 185000, 190000, 195000, 200000];
    const calculateLabor = (teamsheetData) => {
        let tempTotalMinutes = {};
        let tempTotalCost = {};

        let tempTeamsheets = {...teamsheetData};
        let tempWeeklyTotalData = {...weeklyTotalData};

        let tempOvtHours = 0;
        let tempOvtDollars = 0;
        let tempBohDollars = 0;
        let tempBohHours = 0;
        let trainingHours = 0;
        let trainingDollars = 0;
        let managerKeyHours = 0;
        let managerKeyDollars = 0;
        Object.keys(tempTeamsheets).forEach((key)=>{
            Object.keys(tempTeamsheets[key]).forEach((jobcodeKey)=>{
                tempTeamsheets[key][jobcodeKey] = tempTeamsheets[key][jobcodeKey].map((shift)=>{
                    let tempShift = {...shift}
                    let detailText = shift.startDate + " " + shift.startTime;
                    tempShift.dateTime = moment(detailText, "YYYY-MM-DD kk:mm");
                    if(!tempTotalMinutes[key]){
                        tempTotalMinutes[key] = 0;
                        tempTotalCost[key] = 0;
                    } 
                    if(tempShift.roleId === "641344903" && tempShift.jobId === "641370186"){
                        managerKeyDollars += shift.totalCost;
                        managerKeyHours += tempShift.duration;
                    }
                    if(roles[tempShift.roleId] && tempShift.house !== 1){
                        tempTotalMinutes[key] += tempShift.duration;
                        tempTotalCost[key] += tempShift.totalCost;
                        tempOvtDollars += parseInt(tempShift.ovtPay);
                        tempOvtHours += parseInt(tempShift.ovtHours);
                        if(tempShift.roleId.toString() === "643775091"){
                            trainingHours += tempShift.duration;
                            trainingDollars += tempShift.totalCost;
                        } else {
                            tempBohDollars += tempShift.totalCost;
                            tempBohHours += tempShift.duration;
                        }

                    }
                    return tempShift;
                })
            })
        })
        setTotalMinutes(tempTotalMinutes);
        setTeamsheet(tempTeamsheets);

        setTotalCost(tempTotalCost);
        tempWeeklyTotalData.bohDollars = tempBohDollars;
        tempWeeklyTotalData.bohHours = tempBohHours;
        tempWeeklyTotalData.ovtCost = tempOvtDollars;
        tempWeeklyTotalData.ovtHours = tempOvtHours;
        tempWeeklyTotalData.totalBohDollars = tempBohDollars + trainingDollars;
        tempWeeklyTotalData.totalBohHours = tempBohHours + trainingHours;
        tempWeeklyTotalData.trainingDollars = trainingDollars;
        tempWeeklyTotalData.trainingHours = trainingHours;
        tempWeeklyTotalData.managerKeyDollars = managerKeyDollars;
        tempWeeklyTotalData.managerKeyHours = managerKeyHours;

        setWeeklyTotalData(tempWeeklyTotalData)
    }
    const loadTeamsheetData = () => {

        axios.get(url + "/teamsheets/boh/"+currentDay.format("YYYY-MM-DD")).then((res)=>{
            calculateLabor(res.data.teamsheets)
            setEmployees(res.data.employees);
            setLocations(res.data.locations);
            setJobcodes(res.data.jobcodes);
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const loadSalesData = () => {
       return axios.get(url + "/financial/data/"+currentDay.format("YYYY-MM-DD"))
        .then((res)=>{
            let tempFiscalInfo = res.data;
            axios.get(url + `/financial/sales/weekly/${tempFiscalInfo.year-1}/${tempFiscalInfo.period}/${tempFiscalInfo.period_week}/`)
            .then((res)=>{
                const tempSalesData = res.data;
                let tempWeeklyTotalData = {
                    guests: 0,
                    sales: 0,
                }
                tempSalesData.forEach((day)=>{
                    tempWeeklyTotalData.guests += day.guest_count
                    tempWeeklyTotalData.sales += day.sales
                })
                setWeeklySalesData(tempWeeklyTotalData)
                setFiscalInfo(tempFiscalInfo);
                setSalesData(tempSalesData);
            })
            .catch((error)=>{
                console.log(error)
            })
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const handleWeekChange = (modifier) =>{
        setCurrentDay(moment(currentDay).add(modifier, "week"))

    }
    const syncHostschedules = () => {
        let tempTotalMinutes = {};
        let tempTotalCost = {};
        enableSnackbar("Loading data")
        axios.get(url + "/teamsheets/boh/update/"+currentDay.format("YYYY-MM-DD")).then((res)=>{
            console.log("Synced hotschedules")
            enableSnackbar("Loaded data successfully")
            let tempTeamsheets = {...res.data.data.teamsheets};
            calculateLabor(tempTeamsheets)
        })
        .catch((error)=>{
            console.log(error);
            enableSnackbar("failed to load data")
        })
    }
    const enableSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarState(true);
    }
    const snackbarClose = () => {
        setSnackbarState(false);
    }
    useEffect(()=>{
        loadSalesData()
        loadTeamsheetData()
    }, [currentDay]);

    return(
        <Box>
                        <Snackbar
                open={snackbarState}
                autoHideDuration={3000}
                onClose={snackbarClose}
                message={snackbarMessage}

            />
                <Box
                    display="flex" 
                    justifyContent="center" 
                    sx={{
                        alignItems:"center"
                    }} 
                >
                    <IconButton
                        onClick={()=>{
                            handleWeekChange(-1)
                        }}
                    ><ArrowBackIosIcon /></IconButton>
                        <Typography 
                            variant="h4"
                            display="flex" 
                            justifyContent="center" 
                            sx={{
                                alignItems:"center"
                            }} 
                        >
                            {startOfWeek ? ("Week of " + currentDay.format("MM/DD/YYYY")) : "Loading Week Data"}                 
                            <IconButton
                                    onClick={()=>{
                                        syncHostschedules();
                                    }}
                                >
                                    <SyncIcon />
                            </IconButton>
                        </Typography>
                    <IconButton
                        onClick={()=>{
                            handleWeekChange(1)
                        }}
                    ><ArrowForwardIosIcon /></IconButton>
                </Box>
            <Grid spacing={2} container>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Card sx={{
                        maxWidth:"300px",
                        padding: 2
                    }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Last Year    
                        </Typography>
                        <Typography variant="h5" component="div">
                            Sales: ${Intl.NumberFormat().format(weeklySalesData.sales)}
                        </Typography>
                        <Typography variant="h5" component="div">
                            Guests: {weeklySalesData.guests}
                        </Typography>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Card sx={{
                        maxWidth:"300px",
                        padding: 2
                    }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Projected sales    
                        </Typography>
                        <Typography variant="h5" component="div">
                            Sales: {Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    maximumFractionDigits: 2,
                                    roundingIncrement: 5
                                    }).format(weeklySalesData.sales * projectedSalesGrowth)}
                        </Typography>
                        <Typography variant="h5" component="div">
                            Guests: {Math.ceil(weeklySalesData.guests * projectedGuestGrowth)}
                        </Typography>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Card sx={{
                        maxWidth:"300px",
                        padding: 2
                    }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Labor at Projected sales    
                        </Typography>
                        <Typography variant="h5" component="div">
                            Total labor: {((weeklyTotalData.totalBohDollars)/(weeklySalesData.sales * projectedSalesGrowth)).toFixed(2)}%
                        </Typography>
                        <Typography variant="h5" component="div">
                            Before Training: {((weeklyTotalData.bohDollars)/(weeklySalesData.sales * projectedSalesGrowth)).toFixed(2)}%
                        </Typography>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Card sx={{
                        maxWidth:"300px",
                        padding: 2
                    }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Overtime Hours and Dollars   
                        </Typography>
                        <Typography variant="h5" component="div">
                            OT Hours: {weeklyTotalData.ovtHours}<br />
                        <Typography variant="h5" component="div">
                            OT dollars: ${Intl.NumberFormat().format((weeklyTotalData.ovtCost/100))}<br />                        </Typography>
                        </Typography>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Card sx={{
                        maxWidth:"300px",
                        padding: 2
                    }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            BOH Scheduled Dollars    
                        </Typography>
                        <Typography variant="h6" component="div">
                            Dollars: ${Intl.NumberFormat().format(((weeklyTotalData.bohDollars+weeklyTotalData.managerKeyDollars)/100))}
                        </Typography>
                        <Typography variant="h6" component="div">
                            Training Dollars: ${Intl.NumberFormat().format((weeklyTotalData.trainingDollars/100))}
                        </Typography>
                        <Typography variant="h6" component="div">
                            Total Dollars: ${Intl.NumberFormat().format((weeklyTotalData.totalBohDollars/100))}
                        </Typography>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Card sx={{
                        maxWidth:"300px",
                        padding: 2
                    }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            BOH Scheduled Hours   
                        </Typography>
                        <Typography variant="h6" component="div">
                            Hours w/o Training: {weeklyTotalData.bohHours/60}
                        </Typography>
                        <Typography variant="h6" component="div">
                            Training Hours: {weeklyTotalData.trainingHours/60}
                        </Typography>
                        <Typography variant="h6" component="div">
                            Hours from key: {weeklyTotalData.managerKeyHours/60}
                        </Typography>
                        <Typography variant="h6" component="div">
                            Total Hours: {(weeklyTotalData.totalBohHours+weeklyTotalData.managerKeyHours)/60}
                        </Typography>
                    </Card>

                </Grid>
                </Grid>
                <HorizontalRule />
                <Grid spacing={2} container>
                {
                    salesForLabor.map((day)=>{
                        return(
                            <React.Fragment>
                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                <Card sx={{
                                    maxWidth:"300px",
                                    padding: 2
                                }}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Labor at {Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                    maximumFractionDigits: 2,
                                                    roundingIncrement: 5
                                                    }).format((day))}
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Total labor: {((weeklyTotalData.totalBohDollars + weeklyTotalData.managerKeyDollars)/(day)).toFixed(2)}%
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Training Effect: {((weeklyTotalData.trainingDollars)/day).toFixed(2)}%
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Key Effect: {((weeklyTotalData.managerKeyDollars)/day).toFixed(2)}%
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        W/O Training or Keys: {((weeklyTotalData.bohDollars)/day).toFixed(2)}%
                                    </Typography>
                                </Card>
        
                            </Grid>
                            </React.Fragment>
                        )
                    })
                }

            </Grid>            

        </Box>
    )
}