import { Box, Typography, Grid, Button} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useInventory } from "../../../context/Inventory";
import moment from "moment-timezone";
import { formatMoney } from "../../../functions";


export default function TopTwenty(props){
    const {inventoryArray, openItemDialog, inventoryDate, previousInventory} = useInventory();
    const [lastWeek, setLastWeek] = useState(null);
    const nav = useNavigate();
    useEffect(()=>{
        if(Object.keys(inventoryArray).length < 1){
            nav("/dashboard/inventory/kitchen")

        }
    }, [])
    useEffect(()=>{
        setLastWeek(moment(inventoryDate).startOf('day').subtract(1, "week"))
        
    }, [inventoryDate])
    return(
        <React.Fragment>
            <Box>
                <Typography>
                    Top 20 Over used
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2}>

                            Name
                    </Grid>
                    <Grid item xs={2}>
                        Unit
                    </Grid>
                    <Grid item xs={2}>
                        Var by Unit
                    </Grid>
                    <Grid item xs={2}>
                        Last Week by unit
                    </Grid>
                    <Grid item xs={2}>
                        Variance By $
                    </Grid>



                    <Grid item xs={2}>

                    </Grid>
                    {
                        Object.keys(inventoryArray).sort((aKey, bKey)=>{
                            const a = inventoryArray[aKey];
                            const b = inventoryArray[bKey];
                            return a.dollarVariance < b.dollarVariance ? 1 : -1
                        }).map((itemKey)=>{
                            const item = inventoryArray[itemKey];
                            return(
                                <React.Fragment key={item.id}>                                
                                    <Grid item xs={2}>
                                        <Button
                                            onClick={()=>{
                                                openItemDialog(item.itemId)
                                            }}
                                        >
                                            {item.name}
                                        </Button>

                                    </Grid>
                                    <Grid item xs={2}>
                                        {item.units}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {item.usageVariance}

                                    </Grid>
                                    <Grid item xs={2}>
                                        {(lastWeek && previousInventory[itemKey]) ? previousInventory[itemKey][0].usageVariance : ""}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {formatMoney((item.dollarVariance), 2, ".", ",")}
                                    </Grid>



                                    <Grid item xs={2}>

                                    </Grid>
                                </React.Fragment>

                            )
                        })
                    }
                </Grid>
            </Box>
        </React.Fragment>
    )
}