import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import { getApiDomain, getOvertimeTrackerApi } from "../App";
export const HotSchedulesContext = React.createContext({});
export const HotSchedulesProvider = ({children}) => {
    const [employees, setEmployees] = useState({});
    const [schedules, setSchedules] = useState({});
    const [jobcodes, setJobcodes] = useState({});
    const [employeeJobcodes, setEmployeeJobcodes] = useState({});
    const [loading, setLoading] = useState(true);
    const [employeeJobcodeLoading, setEmployeeJobcodeLoading] = useState(false);
    const url = getApiDomain();
    const overtimeApi = getOvertimeTrackerApi();
    const loadEmployees = () => {
        axios.get(url + "/employees").then((res)=>{
            const employeeDict = {};
            res.data.employees.forEach((employee)=>{
                employeeDict[employee.id] = employee
            })
            setSchedules(res.data.schedules)
            console.log(res.data.jobCodes   )
            setJobcodes(res.data.jobCodes);
            setEmployees(employeeDict)
            setLoading(false);
        }) ;
    }
    const getServerIdByName = (name) => {
        let keys = Object.keys(employees);
        for(let x = 0; x < keys.length; x++){
            if(employees[keys[x]].name.toLowerCase() === name.toLowerCase()){
                return keys[x];
            }
        }
        return null;
    }
    const loadEmployeeJobcodes = () =>{
        console.log(overtimeApi + "/json/getemployeejobcodes")
        axios.get(overtimeApi + "/json/getemployeejobcodes").then((res)=>{
            const jobcodeDict = {};
            console.log(res.data.data)
            res.data.data.forEach((jobcode)=>{
                if(jobcodeDict[jobcode.employeeId])
                {
                    jobcodeDict[jobcode.employeeId][jobcode.jobId] = (jobcode);
                } else {
                    jobcodeDict[jobcode.employeeId]={};
                    jobcodeDict[jobcode.employeeId][jobcode.jobId] = (jobcode);

                }
            })
            setEmployeeJobcodes({...jobcodeDict})

        }).catch((error)=>{
            console.log(error)
        });
    }
    useEffect(()=>{
        loadEmployees();
        loadEmployeeJobcodes();
    }, [])
    return(<HotSchedulesContext.Provider value={{employees,getServerIdByName, jobcodes, loadEmployeeJobcodes, employeeJobcodes, setEmployeeJobcodes, loadEmployees, schedules, loading}}>{children}</HotSchedulesContext.Provider>)
}

export const useHotSchedules= ()=>{
    return useContext(HotSchedulesContext)
}