import {Avatar, Box, Paper, Toolbar, IconButton, Typography, Divider, ButtonGroup, Button} from "@mui/material";
import {useNavigate, useParams} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, {useEffect, useState} from "react";
import axios from "axios";


const url = process.env.REACT_APP_API


export const UserProfile = (props)=>{
    const { id } = useParams();
    const nav = useNavigate();
    const [userData, setUserData] = useState({});

    const stringAvatar = (name) => {
        return {
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }
      console.log(id)
    const loadUser = async () =>{
        console.log(`${url}/user/${id}`);
        axios.get(`${url}/user/${id}`).then((res)=>{
            console.log(res.data);
            setUserData(res.data.user)
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    useEffect(()=>{
        loadUser();
    }, [])
    return(
        <Box>
            <Paper
            >
                <Toolbar
                    sx={{
                        m: -2, 
                        display: "flex"
                    }}
                >
                    <IconButton onClick={()=>{
                        nav(-1);}}>
                        <ArrowBackIcon />
                    </IconButton>
                </Toolbar>
                <Box
                    sx={{
                        p: 2
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <Avatar
                            sx={{
                                width: 100,
                                height: 100,
                                m: 1
                            }}
                            {...stringAvatar(`${userData.first_name} ${userData.last_name}`)} 
                        />
                        <Typography variant="h2">
                            {userData.first_name} {userData.last_name}
                        </Typography>
                    </Box>
                    <Divider />

                </Box>
                <Box
                    sx={{
                        display: "flex",
                        m: .5,
                        width: "100%",
                        p: 2,
                        alignSelf: "flex-end",
                        justifyContent: "right"
                    }}
                >
                    <ButtonGroup
                    >
                        <Button
                            variant="contained"
                            color="error"
                        >
                            Delete User
                        </Button>
                    </ButtonGroup>
                </Box>
            </Paper>
        </Box>
    )
}