import React, { useEffect, useState } from "react";
import moment from "moment-timezone"
import { getApiDomain, getOvertimeTrackerApi } from "../../App";
import { useHotSchedules } from "../../context/HotSchedules";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
const overtimeTrackerUrl = getOvertimeTrackerApi();


export default function RequestOfPopOver(props){
    const {employees} = useHotSchedules();
    const {closeCallBack, open, setOpen} = props;

    const handleClose = () => {
        setOpen(false)
        setEmployee({label: "None"});

        if(closeCallBack){
            closeCallBack();
        }
    }
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [selectedEmployee, setEmployee] = useState({label: "None"});
    const [note, setNote]= useState("");
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    }
    const handleEndDateChange = (newValue) => {
        setEndDate(newValue)
    }
    const handleSubmit = async () =>{
        let data = {
            employeeId: selectedEmployee.key,
            startDate: startDate.startOf("day").format(),
            endDate: endDate.add(1, "day").startOf("day").format(),
            note: note
        }
        console.log(data)
        //data = JSON.stringify(data);
        axios.post(overtimeTrackerUrl + '/json/insertrequestoff', data).then((res)=>{
            console.log(res.data)
        })
        console.log("Submitting")
        setEmployee({label: "None"});

    }
    return(
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Add a new request off
                </DialogTitle>
                    <DialogContent>
                        <p />
                        <Autocomplete
                            id="employee-name"
                            value={selectedEmployee}
                            onChange={(event, newValue)=>{
                                console.log(newValue)
                                setEmployee(newValue)
                            }

                            }
                            options={Object.keys(employees).sort((a, b)=>{
                                if(employees[a].name > employees[b].name){
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }).map((key)=>{
                                return(
                                    {
                                        key: key, 
                                        label:employees[key].name
                                    }
                                    )
                            })}
                            
                            sx={{width: 300}}
                            renderInput={(params)=> <TextField {...params} label="Employee Name" />}
                        />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <p />
                            <MobileDatePicker
                                label="Start Date"
                                inputFormat="MM/DD/YYYY"
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <p />
                            <MobileDatePicker
                                label="End Date"
                                inputFormat="MM/DD/YYYY"
                                value={endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            </LocalizationProvider>
                            <br />
                            <TextField id="note" label="Reason" variant="standard" onChange={(event)=>{
                                setNote(event.target.value)
                                console.log(note)
                            }} />

                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>.
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}