import React from "react";
import { Box } from "@mui/material";
import Teams from "./Teams/Teams";
import TeamOverview from "./Teams/TeamOverview";
import {
    Switch,
    Route,
    useRouteMatch,
    Routes
  } from "react-router-dom";

export default function GiftCardOverview(props){
    return(
        <Box>
            <Routes>
                <Route path="modify" element={<Teams />} />
                <Route index element={<TeamOverview />} />
            </Routes>
                
        </Box>
    )
}