import { useEffect, useState } from "react";
import "./App.css";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { Dashboard } from './components/admin/dashboard';


export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT || 80;
    const apiUrl =  process.env.REACT_APP_API_URL  ||  `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getOvertimeTrackerApi() {
    const apiUrl =  process.env.REACT_APP_HOTSCHEDULES_API_URL  ||  `http://localhost:8080`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_PORT ? process.env.REACT_APP_WEBSITE_URL+":"+process.env.REACT_APP_WEBSITE_PORT : process.env.REACT_APP_WEBSITE_URL : `http://localhost:${websitePort}`;
    return websiteUrl;
}
console.log(getApiDomain());
console.log(getWebsiteDomain());


function App() {
    let [showSessionExpiredPopup, updateShowSessionExpiredPopup] = useState(false);
    /*useEffect(()=>{
        axios.get("https://api.crusync.com/sessioninfo").then((res)=>{
            console.log(res);
            console.log("Session info")
        })
    }, [])*/
    return (
        <div className="App">
            <Router>
                    <div className="fill">
                        <Routes>
                            <Route
                                path="/*"
                                element={
                                        <Dashboard />
                                }
                            />
                        </Routes>
                    </div>
            </Router>
        </div>
    );
}

export default App;
